import React, { Fragment, useState,useEffect } from 'react';
import axios from 'axios';
import useAdminCheck from './rechte_admin';
import Accordion from 'react-bootstrap/Accordion';

const Einstellungen = () => {

  const uuidTrainerdb = localStorage.getItem("Id");

  const vollname = localStorage.getItem("Vollname");
  const username = localStorage.getItem("Vollname");
  const [newPassword, setNewPassword] = useState('');
  const [Password, setPassword] = useState('');
   const [role, setRole] = useState('');
  const [message, setMessage] = useState('');
    const unbekannt = '';
    const [trainer, setTrainer]=useState('');
    const schluessel = 'f6eaELeHVG8ugAn';
    const[trainerdb,setTrainerdb]=useState('');







     // Individuelle Zustände für die Formularfelder
  const [trainer_straße, setTrainerStraße] = useState('');
  const [Wohnort, setWohnort] = useState('');
  const [trainer_plz, setTrainerPlz] = useState('');
  const [trainer_mail, setTrainerMail] = useState('');
  const [trainer_telefonnummer, setTrainerTelefonnummer] = useState('');
  const [Steuernummer, setSteuernummer] = useState('');
  const [Umsatzsteuerregel, setUmsatzsteuerregel] = useState('');
  const [Umsatzsteuernummer, setUmsatzsteuernummer] = useState('');
  const [trainer_bank, setTrainerBank] = useState('');
  const [trainer_rechnr, setTrainerRechnr] = useState('');
  const [trainer_tagesanzahl, setTrainerTagesanzahl] = useState('');

  


    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
          // Passwortänderungsanforderung an den Server senden
          const response = await axios.put('https://db.xocore.de/pwchange', { username, newPassword });
          setMessage(response.data.message);
        } catch (error) {
          setMessage('Fehler beim Ändern des Passworts');
          console.error('Fehler beim Ändern des Passworts:', error);
        }
      };

  // Update handler for the personal data form
  const trainer_safe = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.put(`https://db.xocore.de/db/trainer-and/${uuidTrainerdb}`, {
        schluessel,
        trainer_straße,
        Wohnort,
        trainer_plz,
        trainer_mail,
        trainer_telefonnummer,
        Steuernummer,
        Umsatzsteuerregel,
        Umsatzsteuernummer,
        trainer_bank,
        trainer_rechnr,
        trainer_tagesanzahl
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Fehler beim Ändern der Benutzerdaten');
      console.error('Fehler beim Ändern der Benutzerdaten:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const auftragResponse = await axios.get(`https://db.xocore.de/db/trainer/${uuidTrainerdb}`, { params: { schluessel } });
        const fetchedData = auftragResponse.data;

        // Set initial data from the API response
        setTrainer(fetchedData);
        setTrainerStraße(fetchedData.trainer_straße || '');
        setWohnort(fetchedData.Wohnort || '');
        setTrainerPlz(fetchedData.trainer_plz || '');
        setTrainerMail(fetchedData.trainer_mail || '');
        setTrainerTelefonnummer(fetchedData.trainer_telefonnummer || '');
        setSteuernummer(fetchedData.Steuernummer || '');
        setUmsatzsteuerregel(fetchedData.Umsatzsteuerregel || '');
        setUmsatzsteuernummer(fetchedData.Umsatzsteuernummer || '');
        setTrainerBank(fetchedData.trainer_bank || '');
        setTrainerRechnr(fetchedData.trainer_rechnr || '');
        setTrainerTagesanzahl(fetchedData.trainer_tagesanzahl || '');


      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [username]);


      
        
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`https://db.xocore.de/db/trainer/${uuidTrainerdb}`)
      .then(response => {
        setTrainerdb(response.data);
        console.log('Projekt-Daten:', response.data);
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
  }, []);


  
      

  
  return (
    <Fragment>
      <div className="container mt-5">
        <h2 className="text-center mb-4">Einstellungen</h2>
        <div className="row">
          {/* Erstes Formular links */}
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
            <Accordion.Header>User </Accordion.Header>
            <Accordion.Body>
                    <div className="mb-3">
                      <label htmlFor="newPassword" className="form-label">Neues Passwort:</label>
                      <input name="newPassword" type="password" id="newPassword" className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                   
                    </div>
                    <button type="submit"  className="btn btn-primary">Passwort ändern</button>
                  </Accordion.Body>
      </Accordion.Item>



            <Accordion.Item eventKey="2">
            <Accordion.Header>Adresse </Accordion.Header>
            <Accordion.Body>
                  <div className="mb-4">
                    <label htmlFor="trainer_straße" className="form-label">Straße:</label>
                    <input type="text" id="trainer_straße" className="form-control" value={trainer_straße} onChange={(e) => setTrainerStraße(e.target.value)} />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="Wohnort" className="form-label">Ort:</label>
                    <input type="text" id="Wohnort" className="form-control" value={Wohnort} onChange={(e) => setWohnort(e.target.value)} />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="trainer_plz" className="form-label">PLZ:</label>
                    <input type="text" id="trainer_plz" className="form-control" value={trainer_plz} onChange={(e) => setTrainerPlz(e.target.value)} />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="trainer_mail" className="form-label">E-Mail:</label>
                    <input type="text" id="trainer_mail" className="form-control" value={trainer_mail} onChange={(e) => setTrainerMail(e.target.value)} />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="trainer_telefonnummer" className="form-label">Telefon:</label>
                    <input type="text" id="trainer_telefonnummer" className="form-control" value={trainer_telefonnummer} onChange={(e) => setTrainerTelefonnummer(e.target.value)} />
                  </div>
                  <button type="button" onClick={trainer_safe} className="btn btn-primary">Personendaten ändern</button>
                </Accordion.Body>
      </Accordion.Item>
                  <Accordion.Item eventKey="1">
                  <Accordion.Header>Steuern/Rechnung </Accordion.Header>
                  <Accordion.Body>
    <div className="mb-4">
      <label htmlFor="Steuernummer" className="form-label">Steuernummer:</label>
      <input 
        type="text" 
        id="Steuernummer" 
        className="form-control" 
        value={Steuernummer} 
        onChange={(e) => setSteuernummer(e.target.value)} 
      />
    </div>
    <div className="mb-4">
      <label htmlFor="Umsatzsteuerregel" className="form-label">Umsatzsteuerregel:</label>
      <input 
        type="text" 
        id="Umsatzsteuerregel" 
        className="form-control" 
        value={Umsatzsteuerregel} 
        onChange={(e) => setUmsatzsteuerregel(e.target.value)}  
      />
    </div>
    <div className="mb-4">
      <label htmlFor="Umsatzsteuernummer" className="form-label">Umsatzsteuernummer:</label>
      <input 
        type="text" 
        id="Umsatzsteuernummer" 
        className="form-control" 
        value={Umsatzsteuernummer} 
        onChange={(e) => setUmsatzsteuernummer(e.target.value)}  
      />
    </div>
    <div className="mb-4">
      <label htmlFor="Rechnungsnummer" className="form-label">Rechnungsnummer:</label>
      <input 
        type="text" 
        id="Rechnungsnummer" 
        className="form-control" 
        value={trainer_rechnr} 
        onChange={(e) => setTrainerRechnr(e.target.value)}  
      />
    </div>
    <div className="mb-4">
      <label htmlFor="Bankverbindung" className="form-label">Bankverbindung:</label>
      <input 
        type="text" 
        id="Bankverbindung" 
        className="form-control" 
        value={trainer_bank} 
        onChange={(e) => setTrainerBank(e.target.value)} 
      />
    </div>
    <button 
      type="button" 
      onClick={trainer_safe} 
      className="btn btn-primary"
    >
      Steuerdaten ändern
    </button>
  </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
                  <Accordion.Header>Trainingstage</Accordion.Header>
                  <Accordion.Body>
    <div className="mb-4">
      <label htmlFor="Trainingstage" className="form-label">Meine geplante freie Kapazität in Tagen:</label>
      <input 
        type="text" 
        id="Steuernummer" 
        className="form-control" 
        value={trainer_tagesanzahl} 
        onChange={(e) => setTrainerTagesanzahl(e.target.value)} 
      />
    </div>
    <div className="mb-4">
      <label htmlFor="Umsatzsteuerregel" className="form-label">Fixierte Trainingstage</label>
      <input 
        type="text" 
        id="Umsatzsteuerregel" 
        className="form-control" 
        disabled
      />
    </div>
    <div className="mb-4">
      <label htmlFor="Umsatzsteuernummer" className="form-label">Noch freie Kapazität</label>
      <input 
        type="text" 
        id="Umsatzsteuernummer" 
        className="form-control" 
        disabled
      />
    </div>


    <button 
      type="button" 
      onClick={trainer_safe} 
      className="btn btn-primary"
    >
      Kapazität anpassen
    </button>
  </Accordion.Body>
      </Accordion.Item>


      </Accordion>
              

              {/* <button type="submit" className="btn btn-primary">Ändern</button>*/}
            </form>
            {message && <p className="mt-3 text-center">{message}</p>}
          </div>
          {/* Zweites Formular rechts */}
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Benutzername:</label>
                <input type="" id="" className="form-control" value={trainer.username}  disabled  />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="form-label">Rechteprofil</label>
                <input type="password" id="role" className="form-control" value="restricted"  disabled />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Trainername</label>
                <input type="text" id="role" className="form-control" value={vollname}  disabled/>
              </div>

             
            </form>
            {message && <p className="mt-3 text-center">{message}</p>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Einstellungen;
