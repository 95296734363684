import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
// Funktion zum Rendern der Autosuggest-Eingabe
const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.ansprechpartner_firmenname} - {suggestion.ansprechpartner_name}
    </div>
  );
  function AnsprechpartnerAutoSuggest({ onAnsprechpartnerSelected }) {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
  



    useEffect(() => {
      // Hier sollte die entsprechende Trainer-Endpunkt-URL verwendet werden
      axios.get('https://db.xocore.de/aps')
        .then(response => {
          setSuggestions(response.data);
        })
        .catch(error => {
          console.error('Fehler beim Laden der Ansprechpartnerdaten:', error);
        });
    }, []);
  
    const getSuggestionValue = (suggestion) => suggestion.ansprechpartner_id.toString();
  
    const onChange = (event, { newValue }) => {
      setValue(newValue);
    };
  


    
    const onSuggestionsFetchRequested = ({ value }) => {
      // Hier werden die Vorschläge korrekt gefiltert
      const filteredSuggestions = suggestions.filter(suggestion =>
        suggestion.ansprechpartner_firmenname.toLowerCase().includes(value.toLowerCase()) ||
        suggestion.ansprechpartner_name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    };
    
      const onSuggestionsClearRequested = () => {
        setSuggestions([]);
      };
    
  
      const onSuggestionSelected = (event, { suggestion }) => {
        // Callback-Funktion aufrufen, wenn ein Ansprechpartner ausgewählt wurde
        onAnsprechpartnerSelected(suggestion);
      };

      const onInputChange = (event, { newValue, method }) => {
        // Wenn der neue Wert leer ist und die Änderung nicht durch eine Auswahl aus den Vorschlägen erfolgt ist,
        // setze den Wert des Eingabefelds manuell zurück
        if (newValue === '' && method !== 'click') {
          setValue('');
        }
      };

      const inputProps = {
        placeholder: 'Ansprechpartner suchen',
        value,
        onChange,
        className: 'form-control', // Bootstrap-Klasse für Textfeld
      };
    
      
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      theme={{
        container: 'autosuggest-container',
        suggestionsContainer: 'dropdown', // Bootstrap-Klasse für Dropdown-Container
        suggestionsList: 'list-group', // Bootstrap-Klasse für die Liste der Vorschläge
        suggestion: 'list-group-item', // Bootstrap-Klasse für jedes Listenelement
      }}
      onKeyUp={(e) => {
        // Wenn die Backspace-Taste gedrückt wird und der Wert leer ist,
        // setzen Sie den Wert des Eingabefelds manuell zurück
        if (e.keyCode === 8 && value === '') {
          setValue('');
        }
      }}
    />
  );
}
  export default AnsprechpartnerAutoSuggest;