import React, { Component } from 'react';
import ReactDOM from 'react-dom';



class AuftragSuche extends Component {
    state = {  } 
    render() { 
        return (
   
        
        <div>
            <form>
        <label htmlFor="Suche" className="form-label">Suche</label>
        <input type="text"  className="form-control" id="Suche" value={this.state.searchTerm} onChange={this.handleInputChange} />
        </form>
        </div>
     );
    }
}
 
export default AuftragSuche;