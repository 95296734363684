import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';

const MaterialList = () => {
    const [materials, setMaterials] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [material, setMaterial] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
          const response = await axios.get('https://db.xocore.de/mt-list');
          // Prüfe, ob ein Treffer für material_typ === 1 vorliegt
          const updatedMaterials = response.data.map(material => {
            if (material.material_typ === 1) {
              return { ...material, material_typ: "Reisespesen" };
            } else {
                return { ...material, material_typ: "Bildungsmaterial" };
            }
          });
          setMaterials(updatedMaterials);
        } catch (error) {
          console.error('Error fetching materials:', error);
        }
      };
    const handleEdit = (material) => {
        setSelectedMaterial(material);
        setShowModal(true);
    };

    const handleEdit1 = (material) => {
      setShowModal1(true);
  };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMaterial(null);
    };
    const handleCloseModal1 = () => {
      setShowModal1(false);
      setMaterial('');
  };


    const handleSaveChanges = async () => {
        try {
          // PUT-Request an die API senden, um Änderungen zu speichern
          await axios.put(`https://db.xocore.de/mt-list/${selectedMaterial.material_id}`, selectedMaterial);
          
          // Erfolgreiche Nachricht anzeigen oder andere entsprechende Aktionen ausführen
          console.log('Änderungen erfolgreich gespeichert.');
        } catch (error) {
          console.error('Fehler beim Speichern der Änderungen:', error);
          // Fehlermeldung anzeigen oder andere entsprechende Aktionen ausführen
        }
      
        // Modal schließen und ausgewähltes Material zurücksetzen
        setShowModal(false);
        setSelectedMaterial(null);
      };
      

      const handleSaveChanges1 = async () => {
        try {
          console.log(material)
          // PUT-Request an die API senden, um Änderungen zu speichern
          await axios.post(`https://db.xocore.de/mt-list/`, material);
          
          // Erfolgreiche Nachricht anzeigen oder andere entsprechende Aktionen ausführen
          console.log('Änderungen erfolgreich gespeichert.');
        } catch (error) {
          console.error('Fehler beim Speichern der Änderungen:', error);
          // Fehlermeldung anzeigen oder andere entsprechende Aktionen ausführen
        }
      
        // Modal schließen und ausgewähltes Material zurücksetzen
        setShowModal(false);
        setMaterial('');
      };

    if (!materials) {
        return (<h1>Loading . . .</h1>)
    }

    return (
        <div>
            <h2 className="mb-4">Material Liste</h2>
            <Button variant="danger" onClick={handleEdit1}>Material hinzufügen</Button>
            {materials.map((material, index) => (
                <div key={index} className="mb-4 border p-3">
                    <h3>{material.material_typ}</h3>
                    <ul className="list-unstyled">
                        <li key={material.material_id} className="mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <p>Beschreibung: {material.material_bezeichnung}</p>
                                    <p>Nettopreis: {material.material_nettopreis}</p>
                                </div>
                                <Button variant="danger" onClick={() => handleEdit(material)}>Bearbeiten</Button>
                            </div>
                        </li>
                    </ul>
                </div>
            ))}


            {/* Modal für die Bearbeitung */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Bearbeiten</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Hier können Formularelemente zur Bearbeitung des ausgewählten Materials eingefügt werden */}
                    {/* Beispiel: */}
                    {selectedMaterial && (
      <div>
        <div className="mb-3">
          <label htmlFor="material_bezeichnung" className="form-label">Beschreibung</label>
          <input type="text" className="form-control" id="material_bezeichnung" value={selectedMaterial.material_bezeichnung} onChange={(e) => setSelectedMaterial({ ...selectedMaterial, material_bezeichnung: e.target.value })} />
        </div>
        <div className="mb-3">
          <label htmlFor="material_nettopreis" className="form-label">Nettopreis</label>
          <input type="number" className="form-control" id="material_nettopreis" value={selectedMaterial.material_nettopreis} onChange={(e) => setSelectedMaterial({ ...selectedMaterial, material_nettopreis: e.target.value })} />
        </div>
      </div>
    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Abbrechen</Button>
                    <Button variant="danger" onClick={handleSaveChanges}>Änderungen speichern</Button>
                </Modal.Footer>
            </Modal>

                        {/* Modal für die Neuen Gegenstand */}
                        <Modal show={showModal1} onHide={handleCloseModal1}>
                <Modal.Header closeButton>
                    <Modal.Title>Neues Material</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Hier können Formularelemente zur Bearbeitung des ausgewählten Materials eingefügt werden */}
                    {/* Beispiel: */}
                    
      <div>
        <div className="mb-3">
          <label htmlFor="material_bezeichnung" className="form-label">Beschreibung</label>
          <input type="text" className="form-control" id="material_bezeichnung" value={material.material_bezeichnung} onChange={(e) => setMaterial({ ...material, material_bezeichnung: e.target.value })} />
        </div>
        <div className="mb-3">
          <label htmlFor="material_nettopreis" className="form-label">Nettopreis</label>
          <input type="number" className="form-control" id="material_nettopreis" value={material.material_nettopreis} onChange={(e) => setMaterial({ ...material, material_nettopreis: e.target.value })} />
        </div>
      </div>
    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal1}>Abbrechen</Button>
                    <Button variant="danger" onClick={handleSaveChanges1}>Material anlegen</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MaterialList;
