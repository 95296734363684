import React, { Component } from "react";
import { Link } from "react-router-dom";

class ProjectElement extends Component {
  state = {};

  getStatusClassName = () => {
    const { status } = this.props;
    switch (status) {
      case "offen":
        return "badge text-bg-primary";
      case "historisch":
        return "badge text-bg-dark";
      case "zugesagt":
        return "badge text-bg-success";
      case "abrechnung":
      case "durchgefuehrt":
        return "badge text-bg-secondary";
      default:
        return "badge text-bg-primary"; // Fallback für unbekannte Statuswerte
    }
  };

  render() {
    const statusClassName = this.getStatusClassName();

    return (
      <div className="list-group-item list-group-item-action">
        <div className="d-flex w-100 justify-content-between">
          <div className="col-md-2">
            <h5 className="mb-1">{this.props.auftraggeber}</h5>
          </div>
          <div>
            <span className={statusClassName}>{this.props.status}</span> 
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <div>{this.props.umfang} Tage // {this.props.preis} EUR</div>
          <div>
            <Link to={`/projekt/${this.props.projektnummer}`}>
              <button
                onClick={this.props.showDetails}
                type="button"
                className="btn btn-secondary"
              >
                Details
              </button>
            </Link>{" "}
          </div>
        </div>
        <p className="mb-1">{this.props.thema}</p>
        <small>{"Projektnummer:" + this.props.auftragsnummer}</small>
      </div>
    );
  }
}

export default ProjectElement;
