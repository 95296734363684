
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../DashboardLayout.css'; // Füge eine eigene CSS-Datei für zusätzliches Styling hinzu
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Dot,
  } from 'recharts';
import AuftragsListeElement from '../auftragsliste_element';
import Navbar from '../navbar';
import Spesenelement from '../spesen_element';




const Spesenübersicht = () => {

   


  const profil = localStorage.getItem("Role");

  const name = localStorage.getItem("Vollname");

  const vollname = localStorage.getItem("Vollname");
  const encodedVollname = encodeURIComponent(vollname); 




    const [tasks, setTasks] = useState([]);


    const [showG, setShowG]=useState(false);

    
    useEffect(() => {
      const checkAdminRole = () => {
        const profil = parseInt(localStorage.getItem("Role"));
        if (profil === 20) {
          setShowG(true);
        }
      };
  
      checkAdminRole();
    }, []);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await fetch(`https://db.xocore.de/tr/viewspesen/${vollname}`);
                const data = await response.json();
                setTasks(data);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, []);


    
      const CustomYAxisTick = (props) => {
        const { x, y, payload } = props;
        return <text x={x} y={y} dy={-10} textAnchor="end" fill="#D6D58E">{`${payload.value / 1000}k`}</text>;
      };
  return (
    <div>
    
    <Container fluid className="dashboard-container">
            <Row className="header-row">
        <Col>
          <h1>Meine Spesen ({vollname})</h1>
        </Col>
      </Row>
   





      <Row className="orders-list-row">
        {/* Liste mit aktuellen Aufträgen */}
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Zeigt deine Spesen  Abrechnung an</Card.Title>
              <Card.Text>
                
              {tasks.map(task => (
                                        <Spesenelement
                                            key={task.id}
                                            task_id={task.material_id}
                                            status={task.task_status}
                                            task_typ={task.task_typ}
                                            preis={task.gesamtpreis}
                                            task_bezugsobjekt={task.material_auftrag}
                                            zusatzinfo={task.name}
                                            showDetails={() => console.log('Details anzeigen für Aufgabe:', task.id)}
                                        />
                                    ))}
                {/* Hier kannst du die Auftragsliste rendern */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
    </div>
  );
};

export default Spesenübersicht;
