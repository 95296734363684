import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from './DataTable';
import { Button } from 'react-bootstrap';

const DataTabe = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('https://db.xocore.de/db/trainer')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Trainerdaten:', error);
      });
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h1>Trainerdatenbank</h1>
        <Button variant="danger">+</Button>
      </div>
      <DataTable data={data} />
    </div>
  );
};

export default DataTabe;
