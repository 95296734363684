import React from 'react';
import { Dropdown } from 'react-bootstrap';

const LogoffDropdown = () => {
  const handleLogoff = () => {
    // Lokalen Speicher leeren
    localStorage.clear();
    // Weiterleitung zur Login-Seite
    window.location.href = '/login';
  };

  const handleMandantenwechsel = () => {
    // Weiterleitung zur Mandantenwechsel-Seite
    window.location.href = '/8/home';
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Menü
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Dropdown.Item onClick={handleMandantenwechsel}>Mandantenwechsel AFPUK Consult</Dropdown.Item>
      <Dropdown.Item onClick={handleLogoff}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LogoffDropdown;
