import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios'
import TrainerNavbar from './trainer_navbar';
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Material from '../material';
import Material2 from '../material2';
import SpesenAnwendung from './waterfall_auf';

// CSS direkt im JSX einfügen
const inlineCSS = `
.centered-field {
  margin: 0 auto;
  max-width: 1400px;
  border: 1px none #ccc;
  padding: 20px;
}`;

function TrainerRechnung() {
    const [trainerData, setTrainerData] = useState({
        trainer_name: '',
        trainer_straße: '',
        trainer_plz: '',
        Wohnort: '',
        Steuernummer: ''
      });

  const { auftragsnummer } = useParams();
  const [auftrag, setAuftrag] = useState(null);
  const [projektUmsatzsteuer, setProjektUmsatzsteuer] = useState(null);
  const [projektid, setProjektid] = useState(null);
  const [km, setKM] = useState(null);
  const [ap, setAP] = useState(null); // Zustand für Ansprechpartner-Daten
const[projektdata, setProjektdata] =useState(null);
  const [showModal, setShowModal] = useState(false);
  const [projektap, setProjektap] = useState(false);
  const [checkliste, setCheckliste] =useState(null)
  const[material,setMaterial]= useState([]);
  const[trmaterial,settrMaterial]= useState([]);
  // Hinzufügen einer neuen Funktion zum Öffnen des Modals
  const handleShowModal = () => setShowModal(true);

  // Hinzufügen einer neuen Funktion zum Schließen des Modals
  const handleCloseModal = () => setShowModal(false);





    const handleRechnungErstellenClick = async () => {
      try {
        const response = await axios.post('https://db.xocore.de/rechnung-erstellen', auftrag, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `rechnung_${auftrag.auftragsnummer}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setShowModal(false); // Modal schließen
      } catch (error) {
        console.error('Fehler beim Erstellen der Rechnung:', error);
      }
    };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/rechnung-erstellen', auftrag, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `rechnung_${auftrag.auftragsnummer}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Fehler beim Erstellen der Rechnung:', error);
    }
  };






  // useEffect(() => {
  //   // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
  //   axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`)
  //     .then(response => {
  //       setAuftrag(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching auftrag details:', error);
  //     });
  // }, [auftragsnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird
  useEffect(() => {
    const fetchProjektUmsatzsteuer = async () => {
      try {
        const response = await axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`);
        const projektNummer = response.data.auftrag_projekt;
        setAuftrag(response.data);
        if (projektNummer) {
          const projektResponse = await axios.get(`https://db.xocore.de/projekt/${projektNummer}`);
          setProjektdata(projektResponse.data);
          setProjektUmsatzsteuer(projektResponse.data.projekt_umsatzsteuer);
          setProjektid(projektResponse.data.projekt_id);
          setKM(projektResponse.data.projekt_kmtyp);
          setCheckliste(projektResponse.data.projekt_checkliste)
  
          if (projektResponse.data.projekt_ansprechpartner) {
            const apResponse = await axios.get(`https://db.xocore.de/ap/${projektResponse.data.projekt_ansprechpartner}`);
            setAP(apResponse.data);
            console.log('Ansprechpartner',apResponse.data)
          }

          axios.get(`https://db.xocore.de/2/mt/${projektNummer}`)
          .then(response => {
            setMaterial(response.data);
            console.log('Material-Daten:', response.data);
          })
          axios.get(`https://db.xocore.de/mt/${projektNummer}`)
          .then(response => {
            settrMaterial(response.data);
            console.log('Material-Daten:', response.data);
          })


        } else {
          console.error('Projekt-Nummer nicht gefunden.', auftragsnummer, projektNummer);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };
  
    if (auftragsnummer) {
      fetchProjektUmsatzsteuer();
    }
  }, [auftragsnummer]);



  useEffect(() => {
    // Fetch the trainer data
    const fetchTrainerData = async () => {
      try {
        const response = await axios.get('https://db.xocore.de/db/trainer/2');
        setTrainerData(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Trainerdaten:', error);
      }
    };

    if (auftragsnummer) {
      fetchTrainerData();
    }
  }, [auftragsnummer]);

  
  if (!auftrag) {
    return <div>Loading...</div>;
  }
  

  

  return (

    <div className="centered-field">
      <style>{inlineCSS}</style>

    <div>

     Trainerrechnung


      <h2>{auftragsnummer} //     Trainerrechnung
      </h2>
      <form className="row g-3">
        {/* Trainer Data Section */}
        <div className="justify-content-end text-end">

          <p>{trainerData.trainer_name}</p> 
          <p>{trainerData.trainer_straße}</p>
          <p>{trainerData.trainer_plz} {trainerData.Wohnort}</p>
          <p>{trainerData.Steuernummer}</p>


        </div>
        <div className="col-md-2">
        <label htmlFor="Auftragsnummer" className="form-label">Auftragsnummer</label>
        <input type="text" readonly className="form-control" id="Auftragsnummer" value={auftragsnummer} disabled />
      </div>
        <div className="col-md-2">
        <label htmlFor="Einsatzdatum" className="form-label">Datum</label>
        <input type="text" readonly className="form-control" id="Einsatzdatum" value={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')} disabled />
      </div>

      <div className="bg-light border"></div>
      <div className="col-md-2">
        <label htmlFor="Umfang" className="form-label">Anzahl</label>
        <input type="text" className="form-control" id="Umfang" value={auftrag.auftrag_umfang} disabled readonly  />
      </div>
      <div className="col-md-8">
    </div>
      <div className="col-md-2">
        <label htmlFor="Tagessatz" className="form-label">Tagessatz</label>

        <input type="text" className="form-control" id="Tagessatz" value={auftrag.auftrag_trainer_tagessatz_zahl} disabled readonly />
      </div>

      <div className="col-md-12">
        <label htmlFor="Auftragsnummer" className="form-label">Beschreibung</label>
        <textarea type="text" readonly className="form-control" id="Auftragsnummer" value={` ${auftrag.auftrag_auftraggeber} - ${auftrag.auftrag_thema} `} disabled/>
      </div>
 
      <div className="bg-light border"></div>




      <div className="col-md-4">
        <label htmlFor="inputState" className="form-label">Umsatzsteuer</label>
        <select id="inputState" className="form-select"  value={projektUmsatzsteuer} disabled readonly >
        <option value="0">Umsatzsteuerfrei</option>
          <option value="1">Umsatzsteuerpflichtig</option>
        </select>
      </div>


      <div className="col-12">


              </div>
              <div className="col-12">
           <button type="button" className="btn btn-secondary" onClick={handleShowModal}>Rechnung erstellen   </button>   
              </div>
              <Accordion defaultActiveKey="0">

              <Accordion.Item eventKey="2">
        <Accordion.Header>Spesen in Abrechnung</Accordion.Header>
                <Accordion.Body>

        <div className="list-group">


                 {material.map(materialItem => (
        <Fragment key={materialItem.material_id}>
          <Material2
            material={materialItem.name}
            anzahl={`${materialItem.anzahl} `}
            icon={materialItem.abgerechnet}
            material_id={materialItem.material_id}

          />
                <div className="col-md-2">
        <label htmlFor="inputState" className="form-label">Fahrtkostenregelung</label>
        <select name="km" id="km" className="form-select" value={km} disabled readonly >
        <option value="0.30">0,30 EUR</option>
          <option value="0.45">0,45 EUR</option>
          <option value="1.00">Pauschal</option>
        </select>
      </div>
        </Fragment>
      ))}
            </div>  
        </Accordion.Body>
      </Accordion.Item>
    
      </Accordion>


    

    </form>
        {/* Neuer Code für das Modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Rechnung erstellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Der Vorgang lässt sich nicht rückgängig machen. Prüfe daher ob alle Spesen erfasst sind.</p>
          {/* Hier kannst du weitere Inhalte des Modals hinzufügen */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" style={{ width: '45%', marginRight: '5px' }} onClick={handleCloseModal}>
            Abbrechen
          </Button>
          <Button variant="primary" style={{ width: '45%', marginRight: '5px' }} onClick={handleRechnungErstellenClick}>

            Sammelabrechnung
          </Button>
        </Modal.Footer>

      </Modal>

    </div>
    </div>
  );
}

export default TrainerRechnung;