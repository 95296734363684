import React, { Component } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faFileInvoice, faFlag, faHourglassStart, faMarsStroke, faSquare, faSquareCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap'; // Importieren von Bootstrap-Komponenten

class Material extends Component {
  state = {
    thumbsUpClicked: {},
    showModal: false // Status für das Anzeigen des Popups
  };

  // Define getStatusClassName method
  getStatusClassName = () => {
    // ... implementation
  };

  handleThumbsUpClick = async (material_id) => {
    this.setState(prevState => ({
      thumbsUpClicked: {
        ...prevState.thumbsUpClicked,
        [material_id]: true
      }
    }));
    try {
      const response = await axios.put(`https://db.xocore.de/tr/spesen/del/${material_id}`);
      console.log('Response from server:', response.data);
      this.setState({ showModal: true }); // Popup anzeigen, wenn das Löschen erfolgreich war
    } catch (error) {
      console.error('Error deleting material:', error);
    }
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    // Mapping-Tabelle für Icons basierend auf this.props.icon
    const iconMappings = {
      1: faSquareCheck,  // Wenn this.props.icon 1 ist, verwenden Sie das faSquareCheck-Icon
      2: faSquareCheck,  // Wenn this.props.icon 2 ist, verwenden Sie das faCoffee-Icon
      3: faHourglassStart, // Wenn this.props.icon 3 ist, verwenden Sie das faHourglassStart-Icon
      4: faFlag // Weitere Zuordnungen können hier hinzugefügt werden
    };

    const selectedIcon = iconMappings[this.props.icon] || faSquare; // Standard-Icon, falls kein passendes gefunden wird

    // Use statusClassName
    const statusClassName = this.getStatusClassName();

    const iconStyle = {
      color: 'grey', // Ändern Sie die Farbe hier entsprechend Ihren Anforderungen
      // Weitere Stilattribute können hier hinzugefügt werden
    };

    return (
      <>
        <div className="list-group-item list-group-item-action">
          <div className="d-flex w-100 justify-content-between">
            <div className="col-md-2"><h5 className="mb-1">{this.props.material}</h5></div>
            <div>{this.props.anzahl} Stück</div>
            <div>
              <FontAwesomeIcon icon={selectedIcon} style={iconStyle} />{/* Dynamisches Icon basierend auf this.props.icon */}
            </div>
            <div>
              <FontAwesomeIcon icon={faTrash} style={iconStyle} onClick={() => this.handleThumbsUpClick(this.props.material_id)} />{/* Dynamisches Icon basierend auf this.props.icon */}
            </div>
          </div>
        

        </div>
        {/* Modal für Erfolgsnachricht */}
        <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Material gelöscht</Modal.Title>
          </Modal.Header>
          <Modal.Body>Das Material wurde erfolgreich gelöscht.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Material;
