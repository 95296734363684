import React from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import LogoffButton from './logoff';



function CustomNavbar() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <img src="/img/logo_afpuk.png" width="50%" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/dashlay/">Home</Nav.Link>
            <NavDropdown title="Aufträge" id="basic-nav-dropdown">
            <NavDropdown.Item href="/all-list/">Gesamtaufträge</NavDropdown.Item> 
            <NavDropdown.Item href="/auftraege/">Aktuelle Aufträge</NavDropdown.Item>
              <NavDropdown.Item href="/tr/auftraege">Meine Aufträge</NavDropdown.Item>
              <NavDropdown.Item href="/tr/spesen">Meine Spesen</NavDropdown.Item>

              <NavDropdown.Item href="/archiv">Auftragsarchiv</NavDropdown.Item>
           
              </NavDropdown>

              <NavDropdown title="Projekte" id="basic-nav-dropdown">
              <NavDropdown.Item href="/projekte">Projekte</NavDropdown.Item>
              <NavDropdown.Item href="/tr/projekt">Meine Projekte</NavDropdown.Item>
              <NavDropdown.Item href="/dealme">Neues Projekt</NavDropdown.Item>


              </NavDropdown>
              <NavDropdown title="Abrechnung" id="basic-nav-dropdown">
              <NavDropdown.Item href="/bill">Rechnungsstellung</NavDropdown.Item>
              <NavDropdown.Item href="/pay">Trainerabrechnung</NavDropdown.Item>


              </NavDropdown>





          {/* <Nav.Link href="/projekte/">Projekte</Nav.Link> */}
            {/* <Nav.Link href="/bill/">Rechnungen</Nav.Link> */}
            <Nav.Link href="/db">Trainer</Nav.Link>

            {/* <Nav.Link href="/dashlay/">Dashboard</Nav.Link>*/}
            <NavDropdown title="Einstellung" id="basic-nav-dropdown">
            <NavDropdown.Item href="/einstellungen">Benutzereinstellungen</NavDropdown.Item>
            <NavDropdown.Item href="/mt-einstellung">Material</NavDropdown.Item>
            <NavDropdown.Item href="/db">Trainer</NavDropdown.Item>

            <NavDropdown.Item href="/mt-einstellung" disabled>Downloads</NavDropdown.Item>
            </NavDropdown>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown>
            
            
                        <Nav.Link href="/auftraege/">Aufträge</Nav.Link>
                                  <Nav.Link href="/tr/auftraege" >Meine Aufträge</Nav.Link>
            
            */
            
            }
          </Nav>
        </Navbar.Collapse>
        <div className="navbar-text">
          <div className="navbar-profile">
            <FontAwesomeIcon icon={faUserCircle} className="profile-icon" />

            <LogoffButton></LogoffButton>



          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
