import React from 'react';


const FileList = () => {
  return (
    <div className="file-list-container">
      <h2>Dateiliste</h2>
      <table className="table file-list-table">
        <thead>
          <tr>
            <th>Dateiname</th>
            <th>Typ</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>example.txt</td>
            <td>
              <i className="file-type-icon fas fa-file"></i> Textdokument
            </td>
            <td className="action-buttons">
              <a href="#" className="btn btn-primary btn-sm">
                Öffnen
              </a>
              <a href="#" className="btn btn-danger btn-sm">
                Löschen
              </a>
            </td>
          </tr>
          <tr>
            <td>example.txt</td>
            <td>
              <i className="file-type-icon fas fa-file"></i> Textdokument
            </td>
            <td className="action-buttons">
              <a href="#" className="btn btn-primary btn-sm">
                Öffnen
              </a>
              <a href="#" className="btn btn-danger btn-sm">
                Löschen
              </a>
            </td>
          </tr>
          <tr>
            <td>example.txt</td>
            <td>
              <i className="file-type-icon fas fa-file"></i> Textdokument
            </td>
            <td className="action-buttons">
              <a href="#" className="btn btn-primary btn-sm">
                Öffnen
              </a>
              <a href="#" className="btn btn-danger btn-sm">
                Löschen
              </a>
            </td>
          </tr>
          
          {/* Weitere Dateien hier */}
        </tbody>
      </table>
    </div>
  );
};

export default FileList;
