import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Dot,
} from 'recharts';

const Dashboard = () => {
  // Dummy-Daten für die Diagramme
  const data1 = [
    { month: 'Jan', sales: 20000, costs: 10000 },
    { month: 'Feb', sales: 22000, costs: 12000 },
    { month: 'Mar', sales: 18000, costs: 9000 },
    { month: 'Jan', sales: 20000, costs: 10000 },
    { month: 'Feb', sales: 22000, costs: 12000 },
    { month: 'Mar', sales: 18000, costs: 9000 },
  ];

  const data2 = [
    { month: 'Jan', lastYear: 1800, currentYear: 2000, previousYear: 1600 },
    { month: 'Feb', lastYear: 2000, currentYear: 2200, previousYear: 1800 },
    { month: 'Mar', lastYear: 1600, currentYear: 1800, previousYear: 1400 },
    // ... weitere Daten
  ];

  const CustomDot = (props) => {
    const { cx, cy, payload, value } = props;
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={0} // Setze den Radius auf 0, um den Punkt zu verbergen
        fill="#042940" // Dunkles Blau
        stroke="#fff"
        strokeWidth={2}
        onMouseOver={() => console.log(`Wert: ${value}`)} // Hier können Sie Ihre eigene Aktion beim Mouseover hinzufügen
      />
    );
  };

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    return <text x={x} y={y} dy={-10} textAnchor="end" fill="#D6D58E">{`${payload.value / 1000}k`}</text>;
  };

  return (
    <div className="container mt-4 rounded" style={{ background: '#fff', borderRadius: '16px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px' }}>
      <div className="row">
        <div className="col-md-6">
          <h2 style={{ color: '#005C53' }}>Monatsumsätze und Monatskosten</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data1} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <defs>
                <linearGradient id="salesGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#042940" stopOpacity={0.8} /> {/* Dunkles Blau */}
                  <stop offset="100%" stopColor="#042940" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="costsGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#005C53" stopOpacity={0.8} /> {/* Türkis */}
                  <stop offset="100%" stopColor="#005C53" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <XAxis dataKey="month" />
              <YAxis tick={<CustomYAxisTick />} axisLine={{ strokeWidth: 0 }} tickLine={{ strokeWidth: 2, stroke: '#666' }} />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="sales"
                stroke="#042940"
                strokeWidth={3}
                fill="url(#salesGradient)"
                name="Monatsumsätze"
                dot={<CustomDot />}
              />
              <Line
                type="monotone"
                dataKey="costs"
                stroke="#005C53"
                strokeWidth={3}
                fill="url(#costsGradient)"
                name="Monatskosten"
                dot={<CustomDot />}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="col-md-6">
          <h2 style={{ color: '#005C53' }}>Monatsumsätze der letzten drei Jahre</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data2} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <defs>
                <linearGradient id="lastYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#9FC131" stopOpacity={0.8} /> {/* Grün-Gelb */}
                  <stop offset="100%" stopColor="#9FC131" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="currentYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#DBF227" stopOpacity={0.8} /> {/* Gelb */}
                  <stop offset="100%" stopColor="#DBF227" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="previousYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#D6D58E" stopOpacity={0.8} /> {/* Hellblau-Grau */}
                  <stop offset="100%" stopColor="#D6D58E" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <XAxis dataKey="month" />
              <YAxis tick={<CustomYAxisTick />} axisLine={{ strokeWidth: 0 }} tickLine={{ strokeWidth: 2, stroke: '#666' }} />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="lastYear"
                stroke="#9FC131"
                strokeWidth={3}
                fill="url(#lastYearGradient)"
                name="Vorjahr"
                dot={<CustomDot />}
              />
              <Line
                type="monotone"
                dataKey="currentYear"
                stroke="#DBF227"
                strokeWidth={3}
                fill="url(#currentYearGradient)"
                name="Aktuelles Jahr"
                dot={<CustomDot />}
              />
              <Line
                type="monotone"
                dataKey="previousYear"
                stroke="#D6D58E"
                strokeWidth={3}
                fill="url(#previousYearGradient)"
                name="Vorvoriges Jahr"
                dot={<CustomDot />}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
