import { useParams, useHistory } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../navbar';
import React, { useEffect, useState } from 'react';
import useAdminCheck from '../rechte_admin';

function AuftragCreate() {

  useAdminCheck();
  const { auftragsnummer, projektnummer, auftraggeber } = useParams();
  const [trainerOptions, setTrainerOptions] = useState([]);
  const [projekt, setProjekt] = useState(null);
  const [projektLoaded, setProjektLoaded] = useState(false);

  const navigate = useNavigate(); // useNavigate hinzugefügt


  const [beschreibung, setBeschreibung] = useState('');
  const [tagessatz, setTagessatz] = useState('');
  const [durchfuehrungsart, setDurchfuehrungsart] = useState('');
  const [trainersatz, setTrainersatz] = useState('');
  const [umfang, setUmfang] = useState('1');
  const [auftragsdatum, setAuftragsdatum] = useState('');
  const [alert, setAlert] = useState(null);
  //const history = useHistory();

  
  const [auftrag, setAuftrag] = useState({
    auftrag_projekt: projektnummer,
    auftrag_datum: new Date(),
    umfang,
  
   
    // Weitere Felder hier hinzufügen
  });


// Zusammengefasste Event Handler
const handleInputChange = (e) => {
  const { name, value } = e.target;

  // Verwende eine Switch-Anweisung, um den entsprechenden State zu aktualisieren
  switch (name) {
    case 'Beschreibung':
      setBeschreibung(value);
      setAuftrag((prevAuftrag) => ({
        ...prevAuftrag,
        beschreibung: value,
      }));
      
      break;
    case 'Tagessatz':

      setAuftrag((prevAuftrag) => ({
        ...prevAuftrag,
        tagessatz: value,
      }));
      break
    case 'Durchfuehrungsart':
      setDurchfuehrungsart(value);
      break;
    case 'Trainersatz':
      setTrainersatz(value);
      setAuftrag((prevAuftrag) => ({
        ...prevAuftrag,
        Trainersatz: value,
      }));
      break;
    case 'Umfang':
      setUmfang(value);
      setAuftrag((prevAuftrag) => ({
        ...prevAuftrag,
        umfang: value,
      }));
      break;
    case 'Einsatzdatum': // Ändere den Fall-Namen auf 'Einsatzdatum'
      setAuftrag((prevAuftrag) => ({
        ...prevAuftrag,
        auftrag_datum: value,
      }));
      break;
    // Füge weitere Cases für andere veränderbare Felder hinzu
    default:
      break;
  }
  
  setAuftrag((prevAuftrag) => ({
    ...prevAuftrag,
    [name]: value,
  }));
};

useEffect(() => {
  // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
  axios.get(`https://db.xocore.de/projekt/${projektnummer}`)
    .then(response => {
      setProjekt(response.data);
      setProjektLoaded(true); // Setze auf true, wenn das Projekt geladen wurde

      
      console.log('Projekt-Daten:', response.data);
    })
    .catch(error => {
      console.error('Error fetching auftrag details:', error);
    });
}, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird




  useEffect(() => {
    // Hier sollte die entsprechende Trainer-Endpunkt-URL verwendet werden
    axios.get('https://db.xocore.de/tr')
      .then(response => {
        setTrainerOptions(response.data);
      })
      .catch(error => {
        console.error('Fehler beim Laden der Trainerdaten:', error);
      });
  }, []);
  console.log('Auftrag erfolgreich erstellt:', auftrag);

  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Hier füge den Axios-Aufruf hinzu, um den Auftrag in der Datenbank zu erstellen
    axios.post('https://db.xocore.de/auftrag', auftrag)
      .then((response) => {
        console.log('Auftrag erfolgreich erstellt:', auftrag);
        setAlert({
          type: 'success',
          message: 'Auftrag erfolgreich erstellt!',
        });
        setTimeout(() => {
          setAlert(null);
        }, 2000);
        // Navigiere zum Detailscreen des neuen Auftrags oder zu einer anderen gewünschten Seite
        //history.push(`/auftragdetails/${response.data.auftrag_id}`);
      })
      .catch((error) => {
        console.error('Fehler beim Erstellen des Auftrags:', error);
        setAlert({
          type: 'danger',
          message: 'Fehler beim Erstellen des Auftrags!',
            });
            setTimeout(() => {
              setAlert(null);
            }, 2000);
      });
  };
  
const Speichernmove =() => {
     // Hier füge den Axios-Aufruf hinzu, um den Auftrag in der Datenbank zu erstellen
     axios.post('https://db.xocore.de/auftrag', auftrag)
     .then((response) => {
       console.log('Auftrag erfolgreich erstellt:', auftrag);
       setAlert({
         type: 'success',
         message: 'Auftrag erfolgreich erstellt!',
       });
       setTimeout(() => {
         setAlert(null);
       }, 2000);
       // Navigiere zum Detailscreen des neuen Auftrags oder zu einer anderen gewünschten Seite
       //history.push(`/auftragdetails/${response.data.auftrag_id}`);
     })
     .catch((error) => {
       console.error('Fehler beim Erstellen des Auftrags:', error);
       setAlert({
         type: 'danger',
         message: 'Fehler beim Erstellen des Auftrags!',
           });
           setTimeout(() => {
             setAlert(null);
           }, 2000);
     });
  navigate(-1);
}

const abbrechen =() => {

  navigate(-1);
}
  return (
   
    <div>
   
      <div className="container mt-3">
                <div className="row">
                {alert && (
            <div className={`alert alert-${alert.type} mt-3`} role="alert">
              {alert.message}
            </div>
          )}
          <div className="col-md-6">

      <h2>Neuer Auftrag// {projektnummer} </h2>
      </div>
      {projektLoaded && (
      <form className="row g-3" onSubmit={handleFormSubmit}>
        
        <div className="col-md-2">
          <label htmlFor="Projektnummer" className="form-label">Projektnummer</label>
          <input type="text" className="form-control" name="Projektnummer" value={projektnummer} disabled readonly />
        </div>
        <div className="col-md-2">
          <label htmlFor="Auftragsnummer" className="form-label">Auftragsnummer</label>
          <input type="text" className="form-control" name="Auftragsnummer" value="" disabled readonly />
        </div>
        {/* Weitere Felder hier hinzufügen */}
        <div className="col-md-6">
          <label htmlFor="Einsatzdatum" className="form-label">Einsatzdatum</label>
          <input type="date" className="form-control" name="Einsatzdatum" value={auftrag.auftrag_datum} onChange={handleInputChange} required/>
        </div>
        <div className="col-md-6">
          <label htmlFor="Auftraggeber" className="form-label">Auftraggeber</label>
          <input type="text" className="form-control" name="Auftraggeber" value={projekt.projekt_auftraggeber} disabled readonly />
        </div>
        <div className="col-6">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input type="text" className="form-control" name="Thema" value={projekt.projekt_thema} disabled readonly/>
      </div>

      <div className="col-md-6">
        <label htmlFor="Beschreibung" className="form-label">Beschreibung</label>
        <input type="text" className="form-control" name="beschreibung" value={auftrag.auftrag_beschreibung}  onChange={handleInputChange}    />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputState" className="form-label">Umsatzsteuer</label>
        <select id="inputState" className="form-select" value={projekt.projekt_umsatzsteuer}  disabled readonly >
        <option value="0">Umsatzsteuerfrei</option>
          <option value="1">Umsatzsteuerpflichtig</option>
        </select>
      </div>
      <div className="col-md-2">
        <label htmlFor="Tagessatz" className="form-label">Tagessatz </label>
        <input type="text" className="form-control" name="Tagessatz"  onChange={handleInputChange} value={projekt.projekt_tagessatz}  disabled readonly />
      </div>

                          <div className="col-md-6">
                      <label htmlFor="Trainername" className="form-label">Trainername</label>
                      <select
                        id="Trainername"
                        className="form-select"
                        value={auftrag.auftrag_trainer}
                        onChange={handleInputChange}
                        name="auftrag_trainer"
                      >
                        {trainerOptions.map(trainer => (
                          <option key={trainer.trainer_id} value={trainer.trainer_name}>
                            {trainer.trainer_name}
                          </option>
                        ))}
                      </select>
                    </div>
      <div className="col-md-2">
        <label htmlFor="Trainersatz" className="form-label">Trainersatz </label>
        <input type="text" className="form-control" name="Trainersatz"  onChange={handleInputChange} value={auftrag.auftrag_trainersatz}   />
      </div>
      <div className="col-md-1">
        <label htmlFor="umfang" className="form-label">Umfang</label>
        <select name="umfang" className="form-select"  onChange={handleInputChange}  value={auftrag.umfang}   >
    <option value="1">1</option>
    <option value="0.66">0.66</option>

    <option value="0.5">0.5</option>
    <option value="0.33">0.33</option>
    <option value="0.25">0.25</option>
        </select>
      </div>
      <div className="col-md-1">
      </div>

      <div className="col-md-2">
        <label htmlFor="inputState" className="form-label">Durchführungsart</label>
        <select id="inputState" className="form-select"    >
        <option value="1">Präsenz</option>
          <option value="0">Digital</option>
        </select>
      </div>


       <div className="col-12">
         <button type="submit" className="btn btn-danger">Speichern & weiterer Auftrag</button>
           <button onClick={abbrechen} className="btn btn-secondary">Abbrechen</button>
     
        </div>
        <div className="col-12">
          <div  onClick={Speichernmove} className="btn btn-danger">Speichern & beenden</div>

        </div>
      </form>
       )}


    </div></div></div>
  );
}

export default AuftragCreate;
