import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Importiere Modal und Button
import RechnungsterminElement from "../rechnungs_element";

// Dummy-Daten für die Projekte
const dummyProjects = [
  { id: 601, name: "Projekt 601" },
  { id: 775, name: "Projekt 775" },
  { id: 999, name: "Projekt 999" },
];

function RechnungAusgang() {
  const [auftragsView, setAuftragsView] = useState(null);
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [checks, setChecks] = useState({
    spesenErfasst: false,
    auftragAbgeschlossen: false,
    rechnungsdatenVollstaendig: false,
  });

  const [showModal, setShowModal] = useState(false); // Zustand für Modal

  useEffect(() => {
    // Hier könnten echte Daten aus der API geladen werden
    axios
      .get("https://db.xocore.de/auftrag") // Passe die URL an
      .then((response) => {
        setAuftrage(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // Läuft nur einmal beim Laden der Komponente

  const showAuftragDetail = (auftragsid) => {
    setAuftragsView(auftragsid);
  };

  // Aufträge filtern: Nur Status "Abrechnung" anzeigen und nach Suchbegriff filtern
  const filteredAuftrage = auftrage.filter((auftrag) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const status = auftrag.auftrag_status.toLowerCase();

    return (
      status === "abrechnung" &&
      (
        auftrag.auftrag_thema.toLowerCase().includes(lowerCaseSearchTerm) || 
        auftrag.auftrag_auftraggeber.toLowerCase().includes(lowerCaseSearchTerm) || 
        auftrag.auftrag_trainer?.toLowerCase().includes(lowerCaseSearchTerm) || 
        new Date(auftrag.auftrag_datum).toLocaleDateString("de-DE").includes(lowerCaseSearchTerm)
      )
    );
  });

  // Check-Status ändern
  const toggleCheck = (checkType) => {
    setChecks((prevChecks) => ({
      ...prevChecks,
      [checkType]: !prevChecks[checkType],
    }));
  };

  // Rechnungsstellung anstoßen
  const handleRechnungStellen = (projektId) => {
    if (
      checks.spesenErfasst &&
      checks.auftragAbgeschlossen &&
      checks.rechnungsdatenVollstaendig
    ) {
      alert(`Rechnung für ${projektId} wird erstellt.`);
      // Logik zur Rechnungsstellung hier einfügen
    } else {
      alert("Alle Checks müssen erfüllt sein, bevor die Rechnung erstellt werden kann.");
    }
  };

  return (
    <Fragment>
      <div>
        <div className="col-mb-6">
          <h3>Abrechnungsübersicht</h3>
          <h5>- Ausgangsrechnungen // Rechnungsstellung</h5>
        </div>
        <div className="col-mb-6">
          {/* Suchfeld direkt in der Übersicht */}
          <input
            type="text"
            className="form-control"
            placeholder="Aufträge durchsuchen..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Button zum Öffnen des Modals */}
      <Button variant="primary" className="mt-4" onClick={() => setShowModal(true)}>
        Abrechenbare Projekte anzeigen
      </Button>

      {/* Modal für die Auswahl der Projekte */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Abrechenbare Projekte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {dummyProjects.map((projekt) => (
              <li key={projekt.id}>
                {projekt.name}{" "}
                <Button
                  variant="success"
                  onClick={() => handleRechnungStellen(projekt.id)}
                  disabled={
                    !(
                      checks.spesenErfasst &&
                      checks.auftragAbgeschlossen &&
                      checks.rechnungsdatenVollstaendig
                    )
                  }
                >
                  Rechnung erstellen
                </Button>
                <div className="checks mt-2">
                  <div>
                    <input
                      type="checkbox"
                      checked={checks.spesenErfasst}
                      onChange={() => toggleCheck("spesenErfasst")}
                    />{" "}
                    Spesen sind Erfasst
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={checks.auftragAbgeschlossen}
                      onChange={() => toggleCheck("auftragAbgeschlossen")}
                    />{" "}
                    Auftrag abgeschlossen
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={checks.rechnungsdatenVollstaendig}
                      onChange={() => toggleCheck("rechnungsdatenVollstaendig")}
                    />{" "}
                    Rechnungsdaten vollständig
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Bestätigen
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Aufträge mit Status "Abrechnung" */}
      <div className="list-group mt-4">
        {filteredAuftrage.map((auftrag) => (
          <Fragment key={auftrag.auftrag_id}>
            <RechnungsterminElement
              showDetails={() => showAuftragDetail(auftrag.auftrag_nummer)}
              datum={new Date(auftrag.auftrag_datum).toLocaleDateString("de-DE")}
              thema={auftrag.auftrag_thema}
              auftragsnummer={auftrag.auftrag_id}
              status={auftrag.auftrag_status}
              auftraggeber={auftrag.auftrag_auftraggeber}
              eingang={auftrag.auftrag_eingangsrechnung_zahlung}
              ausgang={auftrag.auftrag_ausgangsrechnung_zahlung}
              trainer={auftrag.auftrag_trainer}
            />
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default RechnungAusgang;
