import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import MaterialList from '../material_list_element';
import Accordion from 'react-bootstrap/Accordion';
import { Button, Modal } from 'react-bootstrap';

const MtEinstellung = () => {

  const vollname = localStorage.getItem("Vollname");
  const username = localStorage.getItem("Vollname");
   const [role, setRole] = useState('');
  const [message, setMessage] = useState('');
    const unbekannt = '';
    const [projektnummer, setProjektnummer] = useState('');


    
    const handleProjektnummerChange = (e) => {
        setProjektnummer(e.target.value);
      };
    
  
  return (
    <Fragment>
      <div className="container mt-5">
        <h2 className="text-center mb-4">Materialverwaltung</h2>
        <div className="row">
          {/* Erstes Formular links */}
          <div className="col-md-6">

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
            <Accordion.Header>Materialkategorien </Accordion.Header>
            <Accordion.Body>
                    <div className="mb-3">
                      <label htmlFor="1Material" className="form-label">Bildungsmaterial</label>

                      <textarea name="1Material" type="textarea" id="1Material" className="form-control" value="Material wird an den Kunden versendet und gesondert mit Umsatzsteuer abgerechnet." disabled/> 
                    </div>
                    <div className="mb-3">
                      <label htmlFor="2Material" className="form-label">Spesen</label>
                      <textarea name="1Material" type="textarea" id="1Material" className="form-control" value="Material wird mit Kundenauftrag abgerechnet. Es bedarf einer Genehmigung der Spesen, sofern diese von Nutzern mit einer Trainerrolle erfasst werden." disabled/> 
                   
                    </div>
                  </Accordion.Body>
      </Accordion.Item>



            <Accordion.Item eventKey="2">
            <Accordion.Header>Direkter Bestellungszugriff </Accordion.Header>
            <Accordion.Body>
              <div className="mb-3">
                <label htmlFor="text" className="form-label">Projektnummer:</label>
                <input type="text" id="projektnummer" className="form-control" value={projektnummer}   onChange={handleProjektnummerChange} />
              </div>
              <Link to={`/bestellung/${projektnummer}`}> <Button variant="danger" >Zur Bestellung</Button></Link>


              </Accordion.Body>
      </Accordion.Item>
                  <Accordion.Item eventKey="1">
                  <Accordion.Header>Informationen </Accordion.Header>
                  <Accordion.Body>
                    <h3>Materialerfassung</h3>
                    Material wird immer <b>netto</b> angegeben und auf Basis des aktuell gültigen MwSt-Satzes an den Kunden berechnet. <br></br><br></br>
                    <b>Empfänger</b> des Materials und Materialrechnung ist immer der fachliche Ansprechpartner im Auftrag<br></br><br></br>
                    Material kann nur <b>einmal abgerechnet</b> werden. Es wird jedoch im Auftrag immer angezeigt. 
              </Accordion.Body>
      </Accordion.Item>


      </Accordion>
              

              {/* <button type="submit" className="btn btn-primary">Ändern</button>*/}
      
            {message && <p className="mt-3 text-center">{message}</p>}
          </div>
          {/* Zweites Formular rechts */}
          <div className="col-md-6">
            <h></h>
            <MaterialList />
            {message && <p className="mt-3 text-center">{message}</p>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MtEinstellung;
