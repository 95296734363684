import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
class FileElement extends Component {
    state = {}

    // Define getStatusClassName method
    getStatusClassName = () => {
        // ... implementation
    };

    render() {
        // Use statusClassName
        const statusClassName = this.getStatusClassName();

        return (
            <div className="list-group-item list-group-item-action">
                <div className="d-flex w-100 justify-content-between">
                    <div className="col-md-8"><h5 className="mb-1">{this.props.name}</h5></div>
                    <div>  {this.props.anzahl}             <Link to={`${this.props.link}`}><Button variant="danger" >Anzeigen</Button></Link> {/* <Button variant="danger" >Löschen</Button>*/}</div>
           
                 
                </div>
                <div> {this.props.uploaddate} </div>
            </div>
        );
    }
}

export default FileElement;

