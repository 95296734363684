import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { faCoffee, faFileInvoice, faFlag, faHourglassStart, faMarsStroke, faSquare, faSquareCheck, faAngleDown,faAngleUp,faRightToBracket,faTrash,faThumbsUp } from '@fortawesome/free-solid-svg-icons'; // Importieren Sie das gewünschte Icon hier

class Material2 extends Component {
    state = {
        deleted: false // Zustand, um zu verfolgen, ob das Material gelöscht wurde

    }

        

    // Define getStatusClassName method
    getStatusClassName = () => {
        // ... implementation
    };

    
      // Function to delete material
      deleteMaterial = () => {
        const { material_id } = this.props;
        axios.put(`https://db.xocore.de/tr/spesen/del/${this.props.material_id}`)
            .then(response => {
                // Handle success, you may want to update the state or do something else
                console.log('Material deleted successfully:', response.data);
                this.setState({ deleted: true }); // Setzen Sie den Zustand auf gelöscht

            })
            .catch(error => {
                // Handle error
                console.error('Error deleting material:', error);
            });
    };

    render() {
                const { deleted } = this.state;
                const listItemClassName = deleted ? "list-group-item list-group-item-action deleted" : "list-group-item list-group-item-action";


                // Mapping-Tabelle für Icons basierend auf this.props.icon
                const iconMappings = {
                    1: faTrash,  // Wenn this.props.icon 1 ist, verwenden Sie das faSquareCheck-Icon
                    2: faTrash,       // Wenn this.props.icon 2 ist, verwenden Sie das faCoffee-Icon
                    3: faTrash,
                    4: faTrash // Wenn this.props.icon 3 ist, verwenden Sie das faHourglassStart-Icon
                    // Weitere Zuordnungen können hier hinzugefügt werden
                };


        // Use statusClassName
        const statusClassName = this.getStatusClassName();

        const iconStyle = {
            color: 'grey', // Ändern Sie die Farbe hier entsprechend Ihren Anforderungen
            // Weitere Stilattribute können hier hinzugefügt werden
        };


                // Stildefinition für die gelöschte Klasse
                const deletedStyle = {
                    backgroundColor: 'black', // Hintergrundfarbe schwarz machen
                    color: 'white' // Textfarbe weiß machen
                };

        return (
            <div className={listItemClassName}>
                <div className="d-flex w-100 justify-content-between">
                    <div className="col-md-2"><h5 className="mb-1">{this.props.material}</h5></div>
                    <div>{this.props.anzahl}  </div>
                    <div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Material2;
