import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";

class RechnungsterminElement extends Component {
    state = {}

    getStatusClassName = () => {
        const { status } = this.props;
        switch (status) {
            case "offen":
                return "badge text-bg-primary";
            case "historisch":
                return "badge text-bg-dark";
            case "zugesagt":
                return "badge text-bg-success";
            case "abrechnung":
            case "durchgefuehrt":
                return "badge text-bg-secondary";
            default:
                return "badge text-bg-primary"; // Fallback für unbekannte Statuswerte
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            switch1: this.props.ausgang,
            switch2: this.props.eingang,
            switch3: false,
            switch4: false,
        };
    }
    handleSwitch1Change = () => {
        this.setState({ switch1: !this.state.switch1 });
    }
    
    handleSwitch2Change = () => {
        this.setState({ switch2: !this.state.switch2 });
    }
    
    handleSwitch3Change = () => {
        this.setState({ switch3: !this.state.switch3 });
    }
    
    handleSwitch4Change = () => {
        this.setState({ switch4: !this.state.switch4 });
    }

    render() {
        const statusClassName = this.getStatusClassName();

        return (
            <div className="list-group-item list-group-item-action">
                <div className="d-flex w-100 justify-content-between">
                    <div className="col-md-2"><h5 className="mb-1">{this.props.auftraggeber}</h5></div>
                    <div>{this.props.datum} - <span className={statusClassName}>{this.props.status}</span></div>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="switch1"
                            onChange={this.handleSwitch1Change}
                            checked={this.state.switch1}
                        />
                        <label className="form-check-label" htmlFor="switch1">Ausgangsrechnung</label>
                    </div>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="switch1"
                            onChange={this.handleSwitch2Change}
                            checked={this.state.switch2}
                        />
                        <label className="form-check-label" htmlFor="switch1">Eingangsrechnung</label>
                    </div>
                    <div>
                        <Link to={`/auftrag_d/${this.props.auftragsnummer}`}>
                            <button onClick={this.props.showDetails} type="button" className="btn btn-secondary">Details anzeigen</button>
                        </Link>{" "}
                        <Link to={`/checkliste/${this.props.auftragsnummer}`}>
                         {/*  <button onClick={this.props.showCheckliste} type="button" className="btn btn-light">Rechnung erstellen</button> */}  
                        </Link>{" "}
  
                    </div>
                </div>
                <p className="mb-1">{this.props.thema}</p>
                <small>{"Auftragsnummer:" + this.props.auftragsnummer + " ("+ this.props.trainer +")"}</small>
            </div>
        );
    }
}

export default RechnungsterminElement;
