import React from 'react';
import { Route, Routes } from 'react-router-dom';

//import * as serviceWorker from '../serviceWorker'
import "../bootstrap/dist/css/bootstrap.min.css"
import TerminListe from './terminList';
import AuftragDetailTrainer from './trainer/termin_identi';
import AuftragCheckiste from './termin_checkliste';
import FileList from './project_files';
import AuftragAendernTrainer from './termin_aendern';
import FileUpload from './FileUpload';
import Login from './login';
import Dashboard from './dashboard';
import AuftragDetail from './av/home';
import ProjektAnsicht from './av/projektansicht';
import ProjektListeAv from './av/projektübersicht';
import TerminListeAv from './av/auftragsübersicht';
import AuftragCreate from './av/auftragerstellen';
import RechnungAV from './av/rechnungsansicht';
import DashboardLayout from './dashlayout';
import HubProjektCreate from './av/hubspot_api';
import ProjektCreate from './projekt';
import Materialbestellung from './av/materialbestellung';
import Spesenprüfung from './av/spesen_pruefen';
import SpesenErfassen from './trainer/spesen_erfassen';
import TerminlisteTR from './trainer/auftragsübersicht_tr';
import RegisterForm from './av/register';
import DateienAnsicht from './trainer/projekt_dateiansicht';
import AVallAuftrag from './av/auftragsansicht_all';
import Einstellungen from './settings';
import Trainerdashboard from './trainer/trainer_Dashboard';
import AvArchiv from './av/auftragsansicht_archiv';
import MtEinstellung from './av/settings-material';
import EmailForm from './e-mail-service';
import TerminListTRAll from './trainer/auftragsübersicht_tr_all';
import Waterfall from './trainer/waterfall_projekt';
import DataTabe from './trainerdb/page';

import TrainerProfil from './trainerdb/profile2';
import TrainerRechnung from './trainer/trainer_rechnung';
import Release from './trainer/release_trainer';
import RechnungErfassen from './trainer/rechnungsupload_tr';
import Spesenübersicht from './trainer/spesenuebersicht';
import RechnungAusgang from './av/ARechnung_Uebersicht';
import RechnungEingang from './av/ERechnung_uebersicht';

const RoutesA = ({ token, setToken }) => {
   
  return (
  <>

    <Routes>
    <Route path="/auftragaendern/:auftragsnummer" element={<AuftragAendernTrainer/>}    />
    <Route path="/mail/" element={<EmailForm/>} />
    <Route path="/tr/projekt/" element={<Waterfall/>} />,
    <Route path="/db/" element={<DataTabe/>} />


    <Route path="/trainerrechnung/:auftragsnummer" element={<TrainerRechnung/>} />

    <Route path="/files/" element={<FileList/>} />
    <Route path="/upload/" element={<FileUpload />} />
    <Route path="/auftrag/:auftragsnummer" element={<AuftragDetailTrainer/>} />
    <Route path="/terminList" component={TerminListe} />
    <Route path="/checkliste/:auftragsnummer" element={ <AuftragCheckiste/>}/>
    <Route path="*" element={<Trainerdashboard />} />
    <Route path="/login" element={<Login setToken={setToken} />} />
    <Route path="/dashboard" element={<Dashboard/>}/>
    <Route path="/auftrag_d/:auftragsnummer" element={<AuftragDetail/>} />
    <Route path="/projekt/" element={<ProjektAnsicht/>} />
    <Route path="/projekt/:projektnummer" element={<ProjektAnsicht/>} />
    <Route path="/projekte/" element={<ProjektListeAv/>} />
    <Route path="/auftraege/" element={<TerminListeAv/>} />
    <Route path="/acreate/" element={<AuftragCreate/>} />
    <Route path="/acreate/:projektnummer" element={<AuftragCreate/>} />
    <Route path="/bill/" element={<RechnungAusgang/>} />
    <Route path="/pay/" element={<RechnungEingang/>} />

    <Route path="/dashlay/" element={<DashboardLayout/>} />
    <Route path="/hubspot/:projektnummer" element={<HubProjektCreate/>} />
    <Route path="/dealme/" element={<ProjektCreate/>} />
    <Route path="/bestellung/:projektnummer" element={<Materialbestellung/>} />
    <Route path="/checkmate/:materialnummer" element={<Spesenprüfung/>} />
    <Route path="/reg/" element={<RegisterForm/>} />
    <Route path="/auft/" element={<AVallAuftrag/>} />
    <Route path="/all-list/" element={<AVallAuftrag/>} />
    <Route path="/archiv/" element={<AvArchiv/>} />    
    <Route path="/einstellungen/" element={<Einstellungen/>} />
    <Route path="/mt-einstellung/" element={<MtEinstellung/>} />
    <Route path="/tr/archiv" element={<TerminListTRAll/>} />
    <Route path="/tr/billupload/:auftragsnummer" element={<RechnungErfassen/>} />



      {/*\TrainerDB      */}
      <Route path="/trainer/profil/:trainerId" element={<TrainerProfil/>} />

    {/*\Trainermodul      */}
    <Route path="/tr/*" element={<Trainerdashboard/>} />
    <Route path="/tr/" element={<Trainerdashboard/>} />
    
    <Route path="/tr/auftrag/:auftragsnummer" element={<AuftragDetailTrainer/>} />
    <Route path="/tr/spesen/:auftragsnummer" element={<SpesenErfassen/>} />
    <Route path="/tr/auftraege/" element={<TerminlisteTR/>} />
    <Route path="/tr/dateiansicht/:projektnummer" element={<DateienAnsicht/>} />

    <Route path="/tr/dashboard/" element={<HubProjektCreate/>} />
    <Route path="/tr/rechnung/" element={<HubProjektCreate/>} />
    <Route path="/tr/einstellungen/" element={<Einstellungen/>} />
    <Route path="/tr/billing/" element={<Release/>} />
    <Route path="/tr/spesen/" element={<Spesenübersicht/>} />




    </Routes>
    </>
  );
};

export default RoutesA;
