// authService.js

import axios from 'axios';

export const login = async (username, password) => {
  try {
    // Hier solltest du eine Anfrage an den Server senden, um das Token zu erhalten
    // Das Beispiel hier verwendet Axios und geht davon aus, dass der Server eine JSON-Antwort mit einem Token sendet

    const response = await axios.post('https://db.xocore.de/login', {
      username,
      password,
    });

    if (response.status === 200) {
      const { token } = response.data;
      return {  token };      
      
    } else {
      throw new Error('Falscher Benutzername oder Passwort');
    }
  } catch (error) {
    throw new Error('Fehler bei der Anmeldung: ' + error.message);
  }
};
