import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import RechnungsterminElement from "../rechnungs_element";

function RechnungEingang() {
  const [auftragsView, setAuftragsView] = useState(null);
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Daten aus der Datenbank abrufen
    axios
      .get("https://db.xocore.de/auftrag") // Passe die URL an
      .then((response) => {
        setAuftrage(response.data);
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Daten:", error);
      });
  }, []);

  const showAuftragDetail = (auftragsid) => {
    setAuftragsView(auftragsid);
    console.log("Auftrags-Details anzeigen für ID:", auftragsid);
  };

  // Filterung: Nur Status "durchgeführt"
  const durchgeführteAuftrage = auftrage.filter(
    (auftrag) => auftrag.auftrag_status?.toLowerCase() === "durchgeführt"
  );

  // Suchfilter: Nur Felder durchsuchen, die im Suchbegriff enthalten sind
  const filteredAuftrage = durchgeführteAuftrage.filter((auftrag) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    // Suchbedingung für Thema, Auftraggeber, Trainer und Datum
    const matchesThema = auftrag.auftrag_thema?.toLowerCase().includes(lowerCaseSearchTerm);
    const matchesAuftraggeber = auftrag.auftrag_auftraggeber?.toLowerCase().includes(lowerCaseSearchTerm);
    const matchesTrainer = auftrag.auftrag_trainer?.toLowerCase().includes(lowerCaseSearchTerm);
    const matchesDatum = new Date(auftrag.auftrag_datum)
      .toLocaleDateString("de-DE")
      .includes(lowerCaseSearchTerm);

    return matchesThema || matchesAuftraggeber || matchesTrainer || matchesDatum;
  });

  return (
    <Fragment>
      <div>
        <div className="col-mb-6">
          <h3>Trainerabrechnung</h3>
          <h5>- Rechnungsprüfung // Zahlungseingang // Eingangsrechnungen</h5>
        </div>
        <div className="col-mb-6">
          {/* Suchfeld direkt in der Übersicht */}
          <input
            type="text"
            className="form-control"
            placeholder="Aufträge durchsuchen..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="list-group">
        {filteredAuftrage.length > 0 ? (
          filteredAuftrage.map((auftrag) => (
            <Fragment key={auftrag.auftrag_id}>
              <RechnungsterminElement
                showDetails={() => showAuftragDetail(auftrag.auftrag_nummer)}
                datum={new Date(auftrag.auftrag_datum).toLocaleDateString("de-DE")}
                thema={auftrag.auftrag_thema}
                auftragsnummer={auftrag.auftrag_id}
                status={auftrag.auftrag_status}
                auftraggeber={auftrag.auftrag_auftraggeber}
                eingang={auftrag.auftrag_eingangsrechnung_zahlung}
                ausgang={auftrag.auftrag_ausgangsrechnung_zahlung}
                trainer={auftrag.auftrag_trainer}
              />
            </Fragment>
          ))
        ) : (
          <p>Keine Ergebnisse gefunden.</p>
        )}
      </div>
    </Fragment>
  );
}

export default RechnungEingang;
