import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

const styles = {
  container: {
    textAlign: 'center',
    margin: '20px',
  },
  dropzone: {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '40px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filePreview: {
    marginTop: '20px',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '48px',
    marginBottom: '10px',
  },
  popup: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    padding: '20px',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    zIndex: '9999',
  },
  loader: {
    borderTop: '16px solid #f3f3f3',
    borderRight: '16px solid #3498db',
    borderBottom: '16px solid #f3f3f3',
    borderLeft: '16px solid #3498db',
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    animation: 'spin 2s linear infinite',
    margin: 'auto',
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
  },
};

const RechnungErfassen = () => {
  const { auftragsnummer } = useParams();
  const [editFields, setEditFields] = useState({
    file: null,
  });
  const [uploading, setUploading] = useState(false);
  const [projektid, setProjektid] = useState(null);
  const [projekt, setProjekt] = useState('1');
  const [Trainer, setTrainer] = useState('');
  const [showPopup, setShowPopup] = useState(false); // State for success popup
  const [showError, setShowError] = useState(false); // State for error popup
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchProjektUmsatzsteuer = async () => {
      try {
        const auftragResponse = await axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`);
        const projektNummer = auftragResponse.data.auftrag_projekt;
        setTrainer(auftragResponse.data.auftrag_trainer);
        if (projektNummer) {
          const projektResponse = await axios.get(`https://db.xocore.de/projekt/${projektNummer}`);
          setProjektid(projektResponse.data.projekt_id);
        } else {
          console.error('Projekt-Nummer nicht gefunden.');
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Projekt-Daten:', error);
      }
    };

    if (auftragsnummer) {
      fetchProjektUmsatzsteuer();
    }
  }, [auftragsnummer]);

  const handleSpesenErfassen = async () => {
    setUploading(true);
    const { file } = editFields;

    const formData = new FormData();
    formData.append('projektid', projektid);
    formData.append('Trainer', Trainer);

    if (file) {
      formData.append('file', file);
    }

    try {
      const response = await axios.post(`https://db.xocore.de/file-upload/${auftragsnummer}`, formData);
      console.log('Spesen erfasst:', response.data);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate(`/tr/nachbefragung/${auftragsnummer}`);
      }, 4000); // 4 seconds before redirect
    } catch (error) {
      console.error('Fehler beim Erfassen der Spesen:', error);
      setShowError(true); // Display error message
      setTimeout(() => {
        setShowError(false); // Hide error message after 4 seconds
      }, 4000);
    } finally {
      setUploading(false);
    }
  };

  const handleFileSelect = (selectedFile) => {
    setEditFields({
      ...editFields,
      file: selectedFile,
    });
  };

  const onDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    handleFileSelect(selectedFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });

  return (
    <div>
      <div className="container">
        Rechnungsupload
        <h2>{projektid}-{auftragsnummer} // Rechnung hochladen </h2>
        <form className="row g-3">
          <div className="col-8">
            <div {...getRootProps()} style={styles.dropzone}>
              <input {...getInputProps()} />
              <FontAwesomeIcon icon={faCloudUploadAlt} style={styles.icon} />
              <p>Drag&Drop eine Datei hier oder klicke, um eine hochzuladen.</p>
            </div>
            {editFields.file && (
              <div style={styles.filePreview}>
                <p>Ausgewählte Datei: {editFields.file.name}</p>
              </div>
            )}
          </div>
          <div className="col-8">
            <button
              type="button"
              className="btn btn-secondary mb-2 w-100"
              onClick={handleSpesenErfassen}
              disabled={uploading}
            >
              {uploading ? <div style={styles.loader}></div> : 'Rechnung hochladen'}
            </button>
            <Link to="#" className="btn btn-danger" onClick={() => navigate(-1)}>
              Zurück
            </Link>
          </div>
        </form>
        {showPopup && ( // Render success popup
          <div style={styles.popup}>
            Rechnung erfolgreich erfasst.
          </div>
        )}
        {showError && ( // Render error popup
          <div style={{ ...styles.popup, ...styles.error }}>
            Fehler beim Hochladen der Rechnung. Bitte versuche es erneut.
          </div>
        )}
      </div>
    </div>
  );
};

export default RechnungErfassen;
