import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import FileElement from "../file-element";
import { useParams } from "react-router-dom";

function DateienAnsicht() {
  const [files, setFiles] = useState([]);
  const {projektnummer} = useParams();

  useEffect(() => {
    // Lade Dateien beim Mounten der Komponente
    axios.get(`https://db.xocore.de/projekt-material/${projektnummer}`)
      .then(response => {
        setFiles(response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Dateien:", error);
      });
  }, []);

  return (
    <Fragment>
      <h1>{projektnummer}//Projektdateien</h1>
      <div className="list-group">
        {files.map(file => (
          <FileElement key={file.id} name={file.files_name} anzahl={file.anzahl} link={file.files_url}/>
        ))}
      </div>
    </Fragment>
  );
}

export default DateienAnsicht;
