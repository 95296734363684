import React, { useState } from 'react';
import axios from 'axios';
import useAdminCheck from '../rechte_admin';

const RegisterForm = () => {
  useAdminCheck();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Benutzerdaten an den Server senden
      const response = await axios.post('https://db.xocore.de/register', { username, password, role });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Fehler beim Registrieren des Benutzers');
      console.error('Fehler beim Registrieren des Benutzers:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Registrieren</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">Benutzername:</label>
          <input type="text" id="username" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Passwort:</label>
          <input type="password" id="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <div className="mb-4">
          <label htmlFor="role" className="form-label">Rolle:</label>
          <input type="text" id="role" className="form-control" value={role} onChange={(e) => setRole(e.target.value)} />
        </div>
        <button type="submit" className="btn btn-primary">Registrieren</button>
      </form>
      {message && <p className="mt-3 text-center">{message}</p>}
    </div>
  );
};

export default RegisterForm;
