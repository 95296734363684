import React, { Fragment, useState, useEffect } from "react";
import Termin from "../termin";
import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import Navbar from "../navbar";
import Test from "../Testumgebung";
import AuftragCheckiste from "../termin_checkliste";
import FileList from "../project_files";
import axios from "axios";  // Importiere Axios
import AuftragSuche from "../auftrag_suchen";
import ProjectElement from"../project_element";
// ... (vorheriger Code)
import useAdminCheck from "../rechte_admin";

function ProjektListeAv() {
  useAdminCheck();
  const [auftragsView, setAuftragsView] = useState(null);
  const [projekte, setProjekte] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("zugesagt");

  useEffect(() => {
    // Hier wird Axios verwendet, um Daten aus der Datenbank abzurufen
    axios.get("https://db.xocore.de/projekt")
      .then(response => {
        setProjekte(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const showAuftragDetail = (projektnummer) => {
    setAuftragsView(projektnummer);
    console.log(auftragsView);
  };

  const filteredProjekte = projekte.filter(projekt => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      projekt.projekt_thema.toLowerCase().includes(lowerCaseSearchTerm) ||
      projekt.projekt_auftraggeber.toLowerCase().includes(lowerCaseSearchTerm) ||
      projekt.projekt_id.toString().includes(lowerCaseSearchTerm) 
    );
  }).filter(projekt => selectedStatus === "" || projekt.projekt_status === selectedStatus);


  return (
    <Fragment>
 
      <div>
        <div className="col-mb-6">
          <h3> Aktuelle Projekte</h3>
         
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Projekte durchsuchen..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="form-select"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">Alle Status</option>
              <option value="offen">offen</option>
              <option value="zugesagt">zugesagt</option>
              <option value="historisch">historisch</option>
              {/* Fügen Sie hier weitere Optionen für verschiedene Status hinzu */}
            </select>
          </div>
        </div>
      </div>
      <div className="list-group">
        {filteredProjekte.map((projekt) => (
          <Fragment key={projekt.projekt_id}>
            <ProjectElement
              showDetails={() => showAuftragDetail(projekt.projekt_id)}
              thema={projekt.projekt_thema}
              auftragsnummer={projekt.projekt_id}
              status={projekt.projekt_status}
              auftraggeber={projekt.projekt_auftraggeber}
              umfang={projekt.projekt_umfang}
              preis={projekt.projekt_preis}
              projektnummer={projekt.projekt_id}
            />
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default ProjektListeAv;
