import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Test from './Testumgebung';
import Navbar from './navbar';
import { useState, useEffect } from 'react';



const styles = {
  container: {
    textAlign: 'center',
    margin: '20px',
  },
  dropzone: {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '40px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filePreview: {
    marginTop: '20px',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '48px',
    marginBottom: '10px',
  },
  popup: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    padding: '20px',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    zIndex: '9999',
  },
  loader: {
    borderTop: '16px solid #f3f3f3',
    borderRight: '16px solid #3498db',
    borderBottom: '16px solid #f3f3f3',
    borderLeft: '16px solid #3498db',
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    animation: 'spin 2s linear infinite',
    margin: 'auto',
  },
  centeredField: {
    margin: '0 auto',
    maxWidth: '1400px',
    border: '1px none #ccc',
    padding: '20px',
  },

};

function AuftragCheckiste() {
  const { auftragsnummer } = useParams();
  const navigate = useNavigate(); // useNavigate hinzugefügt

  const [auftrag, setAuftrag] =useState('');
  const [checkliste, setCheckliste] =useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const auftragResponse = await axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`);
        setAuftrag(auftragResponse.data);

        const checklisteResponse = await axios.get(`https://db.xocore.de/checkliste/${auftragResponse.data.auftrag_projekt}`);
        setCheckliste(checklisteResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);





  return (
    <div style={styles.centeredField}>

    <div>
     
        


      <h2>{auftragsnummer} // AuftragCheckliste</h2>
      <form className="row g-3">
      <div className="col-md-2">
        <label htmlFor="Auftragsnummer" className="form-label">Auftragsnummer</label>
        <input type="text" readonly className="form-control" id="Auftragsnummer" value={auftragsnummer} disabled readonly/>
      </div>
      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">Projektnummer</label>
        <input type="text" readonly className="form-control" id="Projektnummer" value={auftrag.auftrag_projekt} disabled readOnly/>
      </div>
      <div className="col-md-2">
        <label htmlFor="Einsatzdatum" className="form-label">Einsatzdatum</label>
        <input type="text" readonly className="form-control" id="Einsatzdatum" value={auftrag.auftrag_datum} disabled readonly/>
      </div>
      <div className="col-md-6">
      <label htmlFor="Auftraggeber" className="form-label">Auftraggeber</label>
        <input type="text" className="form-control" id="Auftraggeber" value={auftrag.auftrag_auftraggeber} disabled readonly/>
      </div>
      <div className="col-6">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input type="text" className="form-control" id="Thema" value={auftrag.auftrag_thema} disabled readonly/>
      </div><div className="col-6"> </div>
      {/* <div className="col-6">
       <label htmlFor="Ansprechpartner" className="form-label"> Ansprechpartner</label>
        <input type="text" className="form-control" id="Ansprechpartner" value="Max Mustermann"disabled readonly />
      </div>

      <div className="col-md-6">
       
      </div>
      <div className="col-4">
        <label htmlFor="EMail" className="form-label">E-Mail</label>
        <input type="text" className="form-control" id="EMail" value="max.mustermann@sparkasse-koelnbonn.de" disabled readonly/>
      </div>
      <div className="col-2">
        <label htmlFor="Telefon" className="form-label">Telefon</label>
        <input type="text" className="form-control" id="Telefon" value="0171-9830601" disabled readonly/>
      </div> */}

      <div className="col-md-6">
        <label htmlFor="Seminarort" className="form-label">Seminarort</label>
        <input type="text" className="form-control" id="Seminarort"  value={checkliste.seminarort} disabled readonly />
      </div>
      <div className="col-md-2">
        <label htmlFor="Umfang" className="form-label">Öffnung Seminarraum</label>
        <input type="text" className="form-control" id="Umfang" value={checkliste.seminarraum_open} disabled readonly  />
      </div>

      <div className="col-md-4">
        <label htmlFor="Name Bahnhof" className="form-label">Name Bahnhof</label>
        <input type="text" className="form-control" id="Name Bahnhof" value={checkliste.bahnhof} disabled readonly />
      </div>
      
      <div className="col-md-6">
        <label htmlFor="WegSeninarRaum" className="form-label">Weg zum Seminarraum/Ansprechpartner</label>
        <textarea type="text" className="form-control" id="WegSeninarRaum"  value={checkliste.seminarraum_weg} disabled readonly />
      </div>
      <div className="col-md-2">
        <label htmlFor="Umfang" className="form-label">Seminarzeit</label>
        <input type="text" className="form-control" id="Umfang"value={checkliste.seminarzeit} disabled readonly  />
      </div>

      <div className="col-md-4">
        <label htmlFor="Name Bahnhof" className="form-label">Adresse Tiefgarage</label>
        <textarea type="text" className="form-control" id="Name Bahnhof" value={checkliste.tiefgarage} disabled readonly />
      </div>
            
      <div className="col-md-6">
        <label htmlFor="Weitere Informationen" className="form-label">Weitere Informationen</label>
        <textarea type="text" className="form-control" id="WeitereInformationen"  value={checkliste.weitere_infos} disabled readonly />
      </div>
      <div className="col-md-2">
       
      </div>

      <div className="col-md-4">
        <label htmlFor="Name Bahnhof" className="form-label">Kontaktdaten Hotel</label>
        <textarea className="form-control" id="Name Bahnhof" value={checkliste.kontaktdaten} disabled readonly />
      </div>

      <div className="col-12">
       {/* <button type="submit" className="btn btn-danger">Auftragsdetails</button>         <button type="submit" className="btn btn-secondary">Dateien   </button>            <button type="submit" className="btn btn-danger">Spesenabrechnung</button>     
        */}      </div>
              <div className="col-12">
         
              </div>
    </form>
    <div class="d-grid gap-2">      <Link to="#" className="btn btn-danger" onClick={() => navigate(-1)}>Zurück</Link> </div>

    </div>
    </div>

  );
}

export default AuftragCheckiste;