import React, { Fragment, useState,useEffect } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';

const Release = () => {



  
      

  
  return (
    <Fragment>
      <div className="container mt-5">
        <h2 className="text-center mb-4">Coming Soon</h2>
        <div className="row">
            <h4>Bitte bereite dich vor und arbeite die Checkliste durch</h4>
          {/* Erstes Formular links */}
          <div className="col-md-6">
            <form >
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
            <Accordion.Header>Benutzerdaten </Accordion.Header>
            <Accordion.Body>
                    <div className="mb-3">
                    Passe deine Benutzerdaten an. Achte insbesondere auf deine Adresse.
                   
                    </div>
                    <button type="submit"  className="btn btn-primary">Ins Profil springen</button>
                  </Accordion.Body>
      </Accordion.Item>



            <Accordion.Item eventKey="2">
            <Accordion.Header>Rechnungsdaten </Accordion.Header>
            <Accordion.Body>
                  <div className="mb-4">
                    Deine Rechnungen werden als XRechnungen erstellt.
                    Pflege bitte daher deine Steuerdaten und deine Rechnungsnummer. 
                    Die Rechnungsnummer ist eine fortlaufende rein nummerische Folge. In der App wird Sie wie folgt ausgegeben:
                    JAHR-$$$$. Die Dollarzeichen stehen für den aktuellen Zähler den du in den Einstellungen vorgibst  
                  </div>
                  <button type="submit"  className="btn btn-primary">Ins Profil springen</button>

                </Accordion.Body>
      </Accordion.Item>
                  <Accordion.Item eventKey="1">
                  <Accordion.Header>Steuern/Rechnung </Accordion.Header>
                  <Accordion.Body>

    <div className="mb-4">
Pflege deine Steuerdaten in der App. Das Feld Umsatzsteuerregel belegst du mit einer "1" sofern du Umsatzsteuer ausweisen möchtest auf deiner Rechnung. Ansonsten wird keine Umsatzsteuer aufgrund der "Kleinunternehmensregel" berechnet. Bitte pflege alle restlichen Felder.    </div>

    <button 
      type="button" 
      onClick="{trainer_safe} "
      className="btn btn-primary"
    >
      Steuerdaten ändern
    </button>
  </Accordion.Body>
      </Accordion.Item>


      </Accordion>
              

              {/* <button type="submit" className="btn btn-primary">Ändern</button>*/}
            </form>
            
          </div>
          {/* Zweites Formular rechts */}

        </div>
      </div>
    </Fragment>
  );
};
export default Release;
