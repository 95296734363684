import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useUserCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('Token');
    const role = localStorage.getItem('Role');

    if (!token) {
      navigate('/login');
    } else if (role === '10') {
      //Nutzungsrole erlaubt
    }else if (role === '20') {
      //Nutzungsrole erlaubt 

    }
    else{
        navigate('/login');
    }
  }, [navigate]); // Füge navigate als Abhängigkeit hinzu
};

export default useUserCheck;
