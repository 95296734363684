import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../navbar";
import Termin from "../termin";
import Accordion from 'react-bootstrap/Accordion';
import Material from "../material";
import CustomAlert from "../customAlert";
import ProjektAuftrag from "../projekt_auftrag_element";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import FileUpload from "../FileUpload";
import FileElement from "../file-element";
import useAdminCheck from "../rechte_admin";
import EmailForm from "../e-mail-service";

function ProjektAnsicht() {

  useAdminCheck();
  const { projektnummer } = useParams();
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projekt, setProjekt] = useState(null);
  const[ap, setAP] =useState(null);
  const [rap,setRAP]=useState(null);
  const[material,setMaterial]= useState([]);
  const [files, setFiles] = useState([]);
  const [buttonColor, setButtonColor] = useState('danger');

  const[Editschutz, setEditschutz]=useState('');



  const [showEmailModal, setShowEmailModal] = useState(false);

  const handleShowEmailModal = () => setShowEmailModal(true);
  const handleCloseEmailModal = () => setShowEmailModal(false);




  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertType("");
    setAlertMessage("");
  };

  useEffect(() => {
    // Lade Dateien beim Mounten der Komponente
    axios.get(`https://db.xocore.de/projekt-material/${projektnummer}`)
      .then(response => {
        setFiles(response.data);
        console.log('File');
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Dateien:", error);
      });
  }, [projektnummer]);


  let editableDataInitialState = {
    projekt_id: "",
    projekt_auftraggeber: "",
    projekt_thema: "",
    projekt_beschreibung: "",
    projekt_umfang: "",
    projekt_tagessatz: "",
    projekt_umsatzsteuer: "",
    projekt_status: "",
    projekt_ma: "",
    projekt_km: "",
    projekt_notiz: "",
    ansprechpartner_name: "",
    ansprechpartner_firmenname: "",
    ansprechpartner_mail: "",
    ansprechpartner_ort: "",
    ansprechpartner_plz: "",
    ansprechpartner_strasse: "",
    ansprechpartner_telefon: "",
    r_ansprechpartner_name: "",
    r_ansprechpartner_firmenname: "",
    r_ansprechpartner_mail: "",
    r_ansprechpartner_ort: "",
    r_ansprechpartner_plz: "",
    r_ansprechpartner_strasse: "",
    r_ansprechpartner_telefon: "",
  };

  const [materialList, setMaterialList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  const handleMaterialClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  

  const handleAddOrder = () => {
    // Send data to the "mt-bestellen" endpoint
    const orderData = {
      material: selectedMaterial,
      quantity: quantity,
    };

    axios.post(`https://db.xocore.de/mt-bestellen/${projektnummer}`, orderData)
      .then(response => {
        console.log("Order successfully placed:", response.data);
        // Close the popup
        setShowModal(false);
      })
      .catch(error => {
        console.error("Error placing order:", error);
      });
  };

  useEffect(() => {
    // Lade Materialien beim Mounten der Komponente
    axios.get("https://db.xocore.de/mt-list")
      .then(response => {
        setMaterialList(response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Materialien:", error);
      });
  }, []);



  const [editableData, setEditableData] = useState(editableDataInitialState);
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`https://db.xocore.de/projekt/${projektnummer}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);


        if(response.data.projekt_ersterechnung ===1){
          setEditschutz(true);
        }

        // Hier editableData aktualisieren
        setEditableData(prevData => ({
          ...prevData,
          projekt_id: response.data.projekt_id,
          projekt_auftraggeber: response.data.projekt_auftraggeber,
          projekt_thema: response.data.projekt_thema,
          projekt_beschreibung: response.data.projekt_beschreibung,
          projekt_umfang: response.data.projekt_umfang,
          projekt_tagessatz: response.data.projekt_tagessatz,
          projekt_umsatzsteuer: response.data.projekt_umsatzsteuer,
          projekt_status: response.data.projekt_status,
          projekt_ma: response.data.projekt_ma,
          projekt_km: response.data.projekt_kmtyp,
          projekt_checkliste: response.data.projekt_checkliste,
          projekt_notiz: response.data.projekt_notiz,

        }));
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
}, [projektnummer]);

console.log(editableData.projekt_km)
  
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`https://db.xocore.de/projekt/${projektnummer}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird



  useEffect(() => {
    // Daten für die spezifische Projektnummer abrufen
    axios.get(`https://db.xocore.de/projekt/${projektnummer}/auftraege`)
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen der Projektnummer ausgeführt wird

  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      auftrag.auftrag_thema?.toLowerCase().includes(lowerCaseSearchTerm) ||
      auftrag.auftrag_trainer?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  

  useEffect(() => {
    // Hier sollte die entsprechende Ansprechpartner-ID abgerufen werden (z.B., indem du Axios verwendest)
    if (projekt && projekt.projekt_ansprechpartner !== null && projekt.projekt_ansprechpartner !== undefined) {
      axios.get(`https://db.xocore.de/ap/${projekt.projekt_ansprechpartner}`)
        .then(response => {
          setAP(response.data ?? null);
          console.log('Ansprechpartner-Daten:', response.data);
  
          // Hier editableData aktualisieren
          setEditableData(prevData => ({
            ...prevData,
            ansprechpartner_name: response.data.ansprechpartner_name,
            ansprechpartner_firmenname: response.data?.ansprechpartner_firmenname,
            ansprechpartner_mail: response.data.ansprechpartner_mail ,
            ansprechpartner_ort: response.data.ansprechpartner_ort,
            ansprechpartner_plz: response.data.ansprechpartner_plz,
            ansprechpartner_strasse: response.data.ansprechpartner_strasse,
            ansprechpartner_telefon: response.data.ansprechpartner_telefon,
          }));
        })
        .catch(error => {
          console.error('Error fetching Ansprechpartner details:', error);
        });
    }
  }, [projekt?.projekt_ansprechpartner]); // Verwende optional chaining für zusätzlichen Schutz
  
  useEffect(() => {
    // Hier sollte die entsprechende Ansprechpartner-ID abgerufen werden (z.B., indem du Axios verwendest)
    if (projekt && projekt.projekt_rechnungsempfaenger !== null && projekt.projekt_rechnungsempfaenger !== undefined) {
      axios.get(`https://db.xocore.de/ap/${projekt.projekt_rechnungsempfaenger}`)
        .then(response => {
          setRAP(response.data);
          console.log('Rechnungsempfänger-Daten:', response.data);
  
          // Hier editableData aktualisieren
          setEditableData(prevData => ({
            ...prevData,
            r_ansprechpartner_name: response.data.ansprechpartner_name,
            r_ansprechpartner_firmenname: response.data.ansprechpartner_firmenname,
            r_ansprechpartner_mail: response.data.ansprechpartner_mail,
            r_ansprechpartner_ort: response.data.ansprechpartner_ort,
            r_ansprechpartner_plz: response.data.ansprechpartner_plz,
            r_ansprechpartner_strasse: response.data.ansprechpartner_strasse,
            r_ansprechpartner_telefon: response.data.ansprechpartner_telefon,
            
          }));
        })
        .catch(error => {
          console.error('Error fetching Rechnungsempfänger details:', error);
        });
    }
  }, [projekt?.projekt_rechnungsempfaenger]); // Verwende optional chaining für zusätzlichen Schutz
  

  useEffect(() => {
    // Lade Projektdaten beim Mounten der Komponente
    axios.get(`https://db.xocore.de/mt/${projektnummer}`)
      .then(response => {
        setMaterial(response.data);
        console.log('Material-Daten:', response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Projektdaten:", error);
      });
  }, []);



  let defaultRecipient= editableData.ansprechpartner_mail;
  let defaultSubject= "Fragen zum Seminar/Coaching";
  let defaultText= "Folgende Materialen werden benötigt bzw. Informationen haben wir für Sie: 3 Metaplanwände; 2 Flipständer; Beamer; Flippapier; Metaplankarten; ausreichend Stifte; Der Trainer benötigt kein Hotel.";

  
  if (!projekt) {
    return <div>Loading...</div>;
  }





  
// Event-Handler für Änderungen an den Formularfeldern
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setEditableData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};


  const handleSpeichernClick = () => {
    // Hier kannst du die zu speichernden Daten aus den State-Variablen abrufen

    setAlertType("success");
    setAlertMessage("Erfolgreich gespeichert!");
    setShowAlert(true);

    const { projekt_id, projekt_auftraggeber, projekt_thema, projekt_beschreibung, projekt_umfang, projekt_tagessatz, projekt_umsatzsteuer, projekt_status,projekt_ma,projekt_kmtyp /* andere Daten */ } = projekt;
    const { ansprechpartner_name, ansprechpartner_firmenname, ansprechpartner_mail, ansprechpartner_ort, ansprechpartner_plz, ansprechpartner_strasse, ansprechpartner_telefon } = ap;
    const { r_ansprechpartner_name, r_ansprechpartner_firmenname, r_ansprechpartner_mail, r_ansprechpartner_ort, r_ansprechpartner_plz, r_ansprechpartner_strasse, r_ansprechpartner_telefon} = rap;
    // Erstelle ein Objekt mit den zu aktualisierenden Daten
    const updatedData = {
      projekt_id,
      projekt_auftraggeber,
      projekt_thema: editableData.projekt_thema,
      projekt_beschreibung: editableData.projekt_beschreibung,
      projekt_umfang: editableData.projekt_umfang,
      projekt_tagessatz: editableData.projekt_tagessatz,
      projekt_umsatzsteuer: editableData.projekt_umsatzsteuer,
      projekt_status: editableData.projekt_status,
      projekt_ma: editableData.projekt_ma,
      projekt_ansprechpartner: projekt.projekt_ansprechpartner,
      projekt_rechnungsempfaenger: projekt.projekt_rechnungsempfaenger,
      projekt_kmtyp: editableData.projekt_km,
      
      
    };
    const updatedDataAP = {
      ansprechpartner_name: editableData.ansprechpartner_name,
      ansprechpartner_firmenname: editableData.ansprechpartner_firmenname,
      ansprechpartner_mail: editableData.ansprechpartner_mail,
      ansprechpartner_ort: editableData.ansprechpartner_ort,
      ansprechpartner_plz: editableData.ansprechpartner_plz,
      ansprechpartner_strasse: editableData.ansprechpartner_strasse,
      ansprechpartner_telefon: editableData.ansprechpartner_telefon,
      // ... (andere Daten)
    };
    const updatedDataRAP = {
      ansprechpartner_name: editableData.r_ansprechpartner_name,
      ansprechpartner_firmenname: editableData.r_ansprechpartner_firmenname,
      ansprechpartner_mail: editableData.r_ansprechpartner_mail,
      ansprechpartner_ort: editableData.r_ansprechpartner_ort,
      ansprechpartner_plz: editableData.r_ansprechpartner_plz,
      ansprechpartner_strasse: editableData.r_ansprechpartner_strasse,
      ansprechpartner_telefon: editableData.r_ansprechpartner_telefon,
      // ... (andere Daten)


      

    };
    console.log('Daten der Aktualisierung:',updatedData );
    // Sende die Anfrage an den Server, um die Daten zu aktualisieren
    axios.put(`https://db.xocore.de/projekt/${projektnummer}`, updatedData)
      .then(response => {
        console.log('Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setProjekt(response.data);

        setAlertType("success");
        setAlertMessage("Erfolgreich gespeichert!");
        setShowAlert(true);
    
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der Daten:', error);
      });
      axios
      .put(`https://db.xocore.de/ap/${projekt.projekt_ansprechpartner}`, updatedDataAP)
      .then((response) => {
        console.log('Ansprechpartner-Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setAP(response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren der Ansprechpartner-Daten:', error);
      });
  
    axios
      .put(`https://db.xocore.de/ap/${projekt.projekt_rechnungsempfaenger}`, updatedDataRAP)
      .then((response) => {
        console.log('Rechnungsempfänger-Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setRAP(response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren der Rechnungsempfänger-Daten:', updatedDataRAP);
      });
      setButtonColor('success');

  };

  if (Array.isArray(material)) {
    // Hier kannst du die map-Funktion sicher verwenden
    material.map(item => console.log(item));
  } else {
    console.error("material ist kein Array:", material);
  }
  




  return (
    <Fragment>

      <CustomAlert
        message={alertMessage}
        type={alertType}
        onClose={handleAlertClose}
      />



      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <h3>{projektnummer}// Projektansicht </h3>
          </div>
          <div className="col-md-6">
          <button type="button" className={`btn btn-${buttonColor} mb-2 w-50`}  onClick={handleSpeichernClick}>
                Speichern
              </button>
              <button type="button"  onClick={handleShowEmailModal} className="btn btn-secondary  mb-2  w-50">
                Checkliste
              </button>
              <button type="button"  className="btn btn-danger mb-2 w-50">
                SWOT
              </button>
              <Link to={`/acreate/${projektnummer}`}>
              <div className="fake-button btn btn-secondary mb-2 w-50">
  Neuer Auftrag
</div>
              </Link>
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            <input
              type="text"
              className="form-control"
              placeholder="Aufträge durchsuchen..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Hier kannst du den Inhalt für die Projektansicht hinzufügen */}
            <form className="row g-3">

      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">No.</label>
        <input type="text" onChange={handleInputChange} className="form-control" id="Projektnummer" value={projekt.projekt_id} disabled />
      </div>

      <div className="col-md-10">
      <label htmlFor="Auftraggeber" className="form-label">Auftraggeber</label>
        <input type="text" className="form-control" name="projekt_auftraggeber" value={projekt.projekt_auftraggeber}   onChange={(e) => {const updatedProjekt = { ...projekt, projekt_auftraggeber: e.target.value };
        setProjekt(updatedProjekt); }}/>
      </div>

      <div className="col-md-6">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input type="text" onChange={handleInputChange} name="projekt_thema"  className="form-control" id="Thema" value={editableData.projekt_thema} />
      </div>
      <div className="col-md-6">
        <label htmlFor="Beschreibung" className="form-label">Beschreibung</label>
        <input type="text"  className="form-control" name="projekt_beschreibung" value={editableData.projekt_beschreibung} onChange={handleInputChange}/>
      </div>

      <div className="col-md-3">
        <label htmlFor="Umfang" className="form-label">Geplanter Umfang</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="projekt_umfang" value={editableData.projekt_umfang}/>
      </div>
      <div className="col-md-4">
        <label htmlFor="Tagessatz" className="form-label">Tagessatz</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="projekt_tagessatz" value={editableData.projekt_tagessatz}/>
      </div>
      <div className="col-md-5">
        <label htmlFor="Umsatzsteuer" className="form-label" >Umsatzsteuer</label>
        <select id="projekt_umsatzsteuer" name="projekt_umsatzsteuer" className="form-select" disabled={Editschutz ? true : false}  onChange={handleInputChange} value={editableData.projekt_umsatzsteuer}  >
        <option value="0">Umsatzsteuerfrei</option>
          <option value="1">Umsatzsteuerpflichtig</option>
        </select>
      </div>
      <div className="col-md-12s">
        <label htmlFor="Status" className="form-label">Status</label>
        <select id="projekt_status" name="projekt_status"  className="form-select" onChange={handleInputChange}   value={editableData.projekt_status} >
          <option value="offen">Offen</option>
          <option value="zugesagt">Zugesagt</option>
          <option value="durchgefuehrt">Durchgeführt</option>
          <option value="abrechnung">Abrechnung</option>
          <option value="historisch">Historisch</option>
          <option value="archiviert">Archiviert</option>
          <option value="löschen">Löschen</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="Projektbeginn" className="form-label">Projektbeginn</label>
        <input
              type="date"
              className="form-control"
              id="Projektbeginn"
              name="Projektbeginn"
              value="01.01.2020"
              onChange={handleInputChange}
              disabled
            />      

      </div>
      <div className="col-md-4">
        <label htmlFor="Projektende" className="form-label">Projektende</label>
        <input
              type="date"
              className="form-control"
              id="Projektende"
              name="Projektende"
              value="01.01.2020"
              onChange={handleInputChange}
              disabled
            />      

      </div>
      <div className="col-md-4">
  	    {/*Platz für noch mehr weiteres Feld */}

      </div>
      <div className="col-md-4">
        <label htmlFor="Status" className="form-label">Projektverantwortlicher </label>
        <select id="projekt_ma" name="projekt_ma" className="form-select"   onChange={handleInputChange} value={editableData.projekt_ma} >
          <option value="Peter">Peter</option>
          <option value="Dennis">Dennis</option>
          <option value="Monika">Monika</option>
          <option value="Wolfgang">Wolfgang</option>
          <option value="Sandro">Sandro</option>


        </select>
      </div>


      
      <div className="col-md-4">
        <label htmlFor="Wegstreckenentschädigung" className="form-label">KM-Abrechnung </label>
        <select id="projekt_km" className="form-select" name="projekt_km" value={editableData.projekt_km} onChange={handleInputChange}  required  >
        <option value="0.30">0,30 EUR</option>
          <option value="0.45">0,45 EUR</option>
          <option value="1.00">Pauschal</option>
        </select>      </div>
        <div className="col-md-4">
        <label htmlFor="Status" className="form-label">Checklistenversand</label>
        <input type="text" className="form-control" name="projekt_tagessatz" value={editableData.projekt_checkliste}disabled/>

      </div>
      <div className="col-md12">
        <label htmlFor="Status" className="form-label">Projektnotiz</label>
        <textarea type="textarea" className="form-control" name="projekt_notiz" value={editableData.projekt_notiz}/>

      </div>
      <div className="bg-light border"></div>
      <div className="bg-light border"></div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Fachlicher Ansprechpartner {projekt.projekt_ansprechpartner}</Accordion.Header>
        <Accordion.Body>
      <div className="col-md-12">
        <label htmlFor="Firmenname" className="form-label">Ansprechpartner</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_firmenname" value={editableData.ansprechpartner_firmenname}  />
      </div>
      <div className="col-md-12">
        <label htmlFor="Ansprechpartner" className="form-label">Ansprechpartner</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_name" value={editableData.ansprechpartner_name} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Telefon" className="form-label">Telefon</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_telefon" value={editableData.ansprechpartner_telefon}/>
      </div>
      <div className="col-md-12">
        <label htmlFor="Mail" className="form-label">Mail</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_mail" value={editableData.ansprechpartner_mail}/>
      </div>
      <div className="col-md-12">
        <label htmlFor="Straße" className="form-label">Straße</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_strasse" value={editableData.ansprechpartner_strasse} />
      </div>
      <div className="col-md-12">
        <label htmlFor="PLZ" className="form-label">PLZ</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_plz" value={editableData.ansprechpartner_plz} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Ort" className="form-label">Ort</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_ort" value={editableData.ansprechpartner_ort} />
        </div>
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Rechnungsempfänger</Accordion.Header>
        <Accordion.Body>

      <div className="col-md-12">
        <label htmlFor="Ansprechpartner" className="form-label">Rechnungsempfänger</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="r_ansprechpartner_firmenname" value={editableData.r_ansprechpartner_firmenname}  />
      </div>
      <div className="col-md-12">
        <label htmlFor="Ansprechpartner" className="form-label">Ansprechpartner</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_name" value={editableData.r_ansprechpartner_name} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Mail" className="form-label">Mail</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="r_ansprechpartner_mail" value={editableData.r_ansprechpartner_mail} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Straße" className="form-label">Straße</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="r_ansprechpartner_strasse" value={editableData.r_ansprechpartner_strasse} />
      </div>
      <div className="col-md-12">
        <label htmlFor="PLZ" className="form-label">PLZ</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="r_ansprechpartner_plz" value={editableData.r_ansprechpartner_plz} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Ort" className="form-label">Ort</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="r_ansprechpartner_ort" value={editableData.r_ansprechpartner_ort} />
      </div>
      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Material</Accordion.Header>
                <Accordion.Body>

        <div className="list-group">
        <button type="button" onClick={handleMaterialClick} className="btn btn-secondary mb-2 w-100">
                Material hinzufügen 
              </button>

                 {material.map(materialItem => (
        <Fragment key={materialItem.material_id}>
          <Material
            material_id={materialItem.material_id}
            material={materialItem.name}
            anzahl={materialItem.anzahl}
            icon={materialItem.abgerechnet}
            
          />
        </Fragment>
      ))}
            </div>  
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Dateien</Accordion.Header>
                <Accordion.Body>
                  
                    <FileUpload projektnummer={projektnummer}/>

        <div className="list-group">
            {files.map(file => (
              <FileElement key={file.files_id} name={file.files_name} link={file.files_url} uploaddate={file.files_uploaddate}/>
            ))}
        

      

                 
            </div>  
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      </form>
            
          </div>
      {/* Modal for adding material */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Material hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleAddOrder}>
            Bestellung hinzufügen
          </Button>
        </Modal.Footer>
      </Modal>
          <div className="col-md-6">
          
            <div className="list-group">
              
              {filteredAuftrage.map((auftrag) => (
                <Fragment key={auftrag.auftrag_nummer}>
                  <ProjektAuftrag
                    datum={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')}
                    thema={auftrag.auftrag_thema}
                    beschreibung={auftrag.auftrag_beschreibung}
                    auftragsnummer={auftrag.auftrag_id}
                    status={auftrag.auftrag_status}
                    trainer={auftrag.auftrag_trainer}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
                 {/* POPUP MATERIAL */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title> Material hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bitte wählen das Material:</p>
          <select
            className="form-control"
            id="material"
            value={selectedMaterial}
            onChange={(e) => setSelectedMaterial(e.target.value)}
          >
            <option value="" disabled>Bitte auswählen</option>
            {materialList.map(materialItem => (
              <option key={materialItem.id} value={materialItem.material_bezeichnung}>
                {materialItem.material_bezeichnung}
              </option>
            ))}
          </select><p>
          <label htmlFor="quantity">Anzahl:</label></p>
          <input
            type="number"
            className="form-control"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          {/* Hier kannst du weitere Inhalte des Modals hinzufügen */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">

          <Button variant="primary" style={{ width: '45%', marginRight: '5px' }} onClick={handleAddOrder}>

            Material bestellen
          </Button>
        </Modal.Footer>
      </Modal>
      

      <Modal show={showEmailModal} onHide={handleCloseEmailModal}>
        <Modal.Header closeButton>
          <Modal.Title>E-Mail schreiben</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailForm      
            defaultRecipient={defaultRecipient}
            defaultSubject={defaultSubject}
            defaultText={defaultText}
            projektnummer={projektnummer}
            projektname={projekt.projekt_thema}
          
          onClose={handleCloseEmailModal} />
        </Modal.Body>
      </Modal>

      </div>


    </Fragment>
  );
}


export default ProjektAnsicht;
