import React, { Fragment, useState, useEffect } from "react";

import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import Navbar from "../navbar";
import Test from "../Testumgebung";
import AuftragCheckiste from "../termin_checkliste";
import FileList from "../project_files";
import axios from "axios";  // Importiere Axios
import AuftragSuche from "../auftrag_suchen";
import TerminTR from "./termin_tr";
import AuftragsListeElementTRAll from "./auftragsliste_element_tr_all";





function TerminListTRAll() {
  const [auftragsView, setAuftragsView] = useState(null);
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");



  useEffect(() => {
    // Hier wird Axios verwendet, um Daten aus der Datenbank abzurufen
    const vollname = localStorage.getItem("Vollname");
    const encodedVollname = encodeURIComponent(vollname); 
    axios.get(`https://db.xocore.de/tr/auftrag?name=${vollname}`)  // Passe die URL an
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Das leere Array stellt sicher, dass useEffect nur einmal beim Laden der Komponente ausgeführt wird



  const showAuftragDetail = (auftragsid) => {
    setAuftragsView(auftragsid);
    console.log(auftragsView);
  };


  // Sortiere die auftrage-Liste nach dem auftrag_datum

  const sortedAuftrage = auftrage.sort((a, b) => {
    const dateA = new Date(a.auftrag_datum);
    const dateB = new Date(b.auftrag_datum);
    return dateB - dateA;
  });




  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      auftrag.auftrag_thema?.toLowerCase().includes(lowerCaseSearchTerm) ||
      auftrag.auftrag_auftraggeber?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });
  return (
    <Fragment>
           
          
     <div> <div className="col-mb-6"><h3> Meine Traineraufträge</h3></div> <div className="col-mb-6">           {/* Suchfeld direkt in der TerminListe */}

</div> </div> 

      <div className="list-group">


          <AuftragsListeElementTRAll  />
      </div>
       
     
    </Fragment>
  );
}

export default TerminListTRAll;
