import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faFileInvoice, faFlag, faHourglassStart, faMarsStroke, faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import Rating from 'react-rating-stars-component';

class TrainerQualifcationElement extends Component {
    state = {
        rating: 3.5 // Beispielhafte Bewertung
    };

    // Define getStatusClassName method
    getStatusClassName = () => {
        // ... implementation
    };

    render() {
        // Mapping-Tabelle für Icons basierend auf this.props.icon
        const iconMappings = {
            1: faSquareCheck,  // Wenn this.props.icon 1 ist, verwenden Sie das faSquareCheck-Icon
            2: faSquareCheck,  // Wenn this.props.icon 2 ist, verwenden Sie das faCoffee-Icon
            3: faHourglassStart,
            4: faFlag // Wenn this.props.icon 3 ist, verwenden Sie das faHourglassStart-Icon
            // Weitere Zuordnungen können hier hinzugefügt werden
        };

        const selectedIcon = iconMappings[this.props.icon] || faSquare; // Standard-Icon, falls kein passendes gefunden wird

        // Use statusClassName
        const statusClassName = this.getStatusClassName();

        const iconStyle = {
            color: 'grey', // Ändern Sie die Farbe hier entsprechend Ihren Anforderungen
            // Weitere Stilattribute können hier hinzugefügt werden
        };

        return (
            <div className="list-group-item list-group-item-action">
                <div className="d-flex w-100 justify-content-between">
                    <div className="col-md-2"><h5 className="mb-1">{this.props.konzept}</h5></div>
                    <div>
                        {this.props.qualistufe} 
                        <Rating 
                            count={5}
                            value={this.props.rating}
                            size={24}
                            edit={false}
                            activeColor="#ffd700"
                        />
                    </div>
                    <div>
        {/* Bisschen Platz hinten */}
                    </div>
                </div>
            </div>
        );
    }
}

export default TrainerQualifcationElement;
