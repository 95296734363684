
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../DashboardLayout.css'; // Füge eine eigene CSS-Datei für zusätzliches Styling hinzu
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Dot,
  } from 'recharts';
import AuftragsListeElement from '../auftragsliste_element';
import TaskElement from '../task_element';
import AuftragsListeElementTR from './afutragliste_element_tr';
import useUserCheck from '../rechte_trainer';


const Trainerdashboard = () => {
  useUserCheck();
 
    // Dummy-Daten für die Diagramme
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);

    const [tasks, setTasks] = useState([]);

    const vollname = localStorage.getItem("Vollname");

    const [auftragsAnzahl, setAuftragsAnzahl] = useState(0); // State für die Anzahl der Aufträge
    const [currentMonthSales, setCurrentMonthSales] = useState(0); // Umsatz für den aktuellen Monat
    const [annualSales, setAnnualSales] = useState(0); // Jahresumsatz



    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await fetch('https://db.xocore.de/tasks');
                const data = await response.json();
                setTasks(data);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`https://db.xocore.de/salesData/${vollname}`);

            const salesData = await response.json();
    
            // Filter data for the current year
// Filter data for the current year
const currentYear = new Date().getFullYear();
const filteredData = salesData
    .filter((entry) => entry.year === currentYear)
    .map((entry) => ({
        month: entry.month, // Convert month to string for consistency with dummy data
        sales: entry.totalUmsatz ? parseFloat(entry.totalUmsatz.replace(',', '')) : 0, // Check if totalUmsatz is defined
        costs: entry.totalKosten ? parseFloat(entry.totalKosten.replace(',', '')) : 0, // Check if totalKosten is defined
    }));

    
            setData1(filteredData);
              console.log('dieses Jahr', currentYear)









              
            const previousYear = currentYear -1 ;
            const previousYearData = salesData
              .filter((entry) => entry.year === previousYear)
              .map((entry) => ({
                month: entry.month,
                sales: parseFloat(entry.totalUmsatz.replace(',', '')),
              }));
    
            const yearBeforePrevious = previousYear + 2;
            const yearBeforePreviousData = salesData
              .filter((entry) => entry.year === yearBeforePrevious)
              .map((entry) => ({
                month: entry.month,
                sales: parseFloat(entry.totalUmsatz.replace(',', '')),
              }));
    
            // Define an array of month abbreviations
            const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
    
            // Fetch data for the three years for data2
            setData2(
              monthAbbreviations.map((monthAbbreviation, index) => {
                const lastYearEntry = previousYearData.find((entry) => entry.month === index + 1);
                const currentYearEntry = filteredData.find((entry) => entry.month === index + 1);
                const previousYearEntry = yearBeforePreviousData.find((entry) => entry.month === index + 1);
    
                return {
                  month: monthAbbreviation,
                  lastYear: lastYearEntry ? lastYearEntry.sales : 0,
                  currentYear: currentYearEntry ? currentYearEntry.sales : 0,
                  previousYear: previousYearEntry ? previousYearEntry.sales : 0,
                };
              })
            );
            
        // Umsatz für den aktuellen Monat berechnen
        const currentMonth = new Date().getMonth() + 1;
        const currentMonthData = filteredData.find((entry) => entry.month === currentMonth);
        if (currentMonthData) {
          setCurrentMonthSales(currentMonthData.sales.toFixed(0)); 
        }        
        
        // Jahresumsatz berechnen
        const annualSalesSum = filteredData.reduce((sum, entry) => sum + entry.sales, 0);
        setAnnualSales(annualSalesSum.toFixed(0)); // Jahresumsatz ohne Nachkommastellen
     
        
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []); 

    






      const CustomDot = (props) => {
        const { cx, cy, payload, value } = props;
        return (
          <Dot
            cx={cx}
            cy={cy}
            r={0} // Setze den Radius auf 0, um den Punkt zu verbergen
            fill="#042940" // Dunkles Blau
            stroke="#fff"
            strokeWidth={2}
            onMouseOver={() => console.log(`Wert: ${value}`)} // Hier können Sie Ihre eigene Aktion beim Mouseover hinzufügen
          />
        );
      };
    
      const CustomYAxisTick = (props) => {
        const { x, y, payload } = props;
        return <text x={x} y={y} dy={-10} textAnchor="end" fill="#D6D58E">{`${payload.value / 1000}k`}</text>;
      };
  return (
    <div>
    
    <Container fluid className="dashboard-container">
      <Row className="header-row">
      <Col>
          <h1>Hallo {vollname}!</h1>
        </Col>
        {/* Wiederhole dieses Block für die restlichen Boxen */}
      </Row>
      <Row className="graph-row">
        {/* 2 Container für Graphen */}
 
        <Col md={0}>         {/* Diese Zahl verändern um zwei graphen einzufügen bestimmt die Breite*/}

        </Col>

      
        <Col md={12}>
        <Card className="info-box">
            <Card.Body>
        <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data2} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}                >
           <defs>
                <linearGradient id="lastYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#9FC131" stopOpacity={0.8} /> {/* Grün-Gelb */}
                  <stop offset="100%" stopColor="#9FC131" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="currentYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#DBF227" stopOpacity={0.8} /> {/* Gelb */}
                  <stop offset="100%" stopColor="#DBF227" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="previousYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#D6D58E" stopOpacity={0.8} /> {/* Hellblau-Grau */}
                  <stop offset="100%" stopColor="#D6D58E" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <XAxis dataKey="month" />
              <YAxis tick={<CustomYAxisTick />} axisLine={{ strokeWidth: 0 }} tickLine={{ strokeWidth: 2, stroke: '#666' }} />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="lastYear"
                stroke="#9FC131"
                strokeWidth={3}
                fill="url(#lastYearGradient)"
                name="Vorjahr"
                dot={<CustomDot />}
              />
              <Line
                type="monotone"
                dataKey="currentYear"
                stroke="#DBF227"
                strokeWidth={3}
                fill="url(#currentYearGradient)"
                name="Aktuelles Jahr"
                dot={<CustomDot />}
              />
              <Line
                type="monotone"
                dataKey="previousYear"
                stroke="#D6D58E"
                strokeWidth={3}
                fill="url(#previousYearGradient)"
                name="Nächstes Jahr"
                dot={<CustomDot />}
              />
                </LineChart>
          </ResponsiveContainer>
          </Card.Body>
          </Card>
        </Col>  </Row>
      <Row className="orders-list-row">
        {/* Liste mit aktuellen Aufträgen */}
        <Col>
          <Card>
            <Card.Body style={{ maxHeight: '600px', overflow: 'auto' }}>
              <Card.Title>Nächste Termine</Card.Title>
              <Card.Text>
              <AuftragsListeElementTR/>
                {/* Hier kannst du die Auftragsliste rendern */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="orders-list-row">
        {/* Liste mit aktuellen Aufträgen */}
        <Col>

        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default Trainerdashboard;
