import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
// Funktion zum Rendern der Autosuggest-Eingabe
const renderSuggestion = (suggestion) => (
  <div>
    {suggestion.ansprechpartner_firmenname} - {suggestion.ansprechpartner_name}
  </div>
);function RechnungsempfängerAutoSuggest({ onRechnungsempfängerSelected }) {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Hier sollte die entsprechende Trainer-Endpunkt-URL verwendet werden
    axios.get('https://db.xocore.de/rps')  // Endpoint 'rps' für Rechnungsempfänger verwenden
      .then(response => {
        setSuggestions(response.data);
      })
      .catch(error => {
        console.error('Fehler beim Laden der Rechnungsempfängerdaten:', error);
      });
  }, []);

  const getSuggestionValue = (suggestion) => suggestion.ansprechpartner_id.toString();

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(suggestions.filter(suggestion =>
      suggestion.ansprechpartner_firmenname.toLowerCase().includes(value.toLowerCase()) ||
      suggestion.ansprechpartner_name.toLowerCase().includes(value.toLowerCase())
    ));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    // Callback-Funktion aufrufen, wenn ein Rechnungsempfänger ausgewählt wurde
    onRechnungsempfängerSelected(suggestion);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={{
        placeholder: 'Rechnungsempfänger suchen',
        value,
        onChange,
        className: 'form-control', // Bootstrap-Klasse für Textfeld
      }}
      theme={{
        container: 'autosuggest-container',
        suggestionsContainer: 'dropdown', // Bootstrap-Klasse für Dropdown-Container
        suggestionsList: 'list-group', // Bootstrap-Klasse für die Liste der Vorschläge
        suggestion: 'list-group-item', // Bootstrap-Klasse für jedes Listenelement
      }}
    />
  );
}

export default RechnungsempfängerAutoSuggest;