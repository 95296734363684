// App.jsx

import React, { useState, useEffect } from 'react';
import RoutesA from './components/Routes';
import CustomNavbar from './components/navbar';
import { Navigate, Route, Routes } from 'react-router-dom';
import TrainerNavbar from './components/trainer/trainer_navbar';
const App = () => {
    const [token, setToken] = useState(null); // Stelle sicher, dass setToken korrekt initialisiert wird
    const [role, setRole] = useState(null);



    useEffect(() => {
      // Rolle aus dem Local Storage abrufen
      const userRole = localStorage.getItem('Role');
      if (userRole) {
        setRole(parseInt(userRole)); // Rolle in Integer umwandeln und setzen
      }
    }, []);




  return (
    <>

      {role === 20 && <CustomNavbar />} {/* Zeige Navbar für Rolle 20 */}
      {role === 21 && <CustomNavbar />} {/* Zeige Navbar für Rolle 21 */}

      {role === 10 && <TrainerNavbar />} {/* Zeige TrainerNavbar für Rolle 10 */}
      <RoutesA />
      <div>
        {/* Hier können Header, Footer oder andere Layout-Komponenten platziert werden */}
      </div>
    </>
  );
};

export default App;
