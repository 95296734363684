import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from "react-router-dom";
const styles = {
  container: {
    textAlign: 'center',
    margin: '20px',
  },
  dropzone: {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '40px', // Größerer Bereich zum Ziehen und Ablegen
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filePreview: {
    marginTop: '20px', // Abstand zum ausgewählten Dateinamen
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '48px',
    marginBottom: '10px', // Abstand zum Text
  },
};

const FileUpload = (props) => {
  const [file, setFile] = useState(null);


  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`https://db.xocore.de/file/${props.projektnummer}`, formData);
      console.log('File uploaded successfully:', response.data);

      // Hier kannst du die Datei weiterleiten, z.B., an SharePoint oder AWS S3
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div style={styles.container}>
      <div {...getRootProps()} style={styles.dropzone}>
        <input {...getInputProps()} />
        <FontAwesomeIcon icon={faCloudUploadAlt} style={styles.icon} />
        <p>Drag & drop eine Datei hier oder klicke, um eine hochzuladen.  </p>
      </div>
      {file && (
        <div style={styles.filePreview}>
          <p>Selected file: {file.name}</p>
          <div onClick={uploadFile} style={styles.button}>
            Upload
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
