import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

class Spesenelement extends Component {
  state = {
    deleted: false, // New state to track delete success
  };

  getStatusClassName = () => {
    switch (this.props.task_typ) {
      case 10:
        return "Neue Reisespesen zu prüfen";
      case 20:
        return "Materialbestellung  für Versand vorbereiten";
      case 30:
        return "Neuer gewonnener Deal - Projekt anlegen";
      case 40:
        return "Neue Projektrechnung erforderlich";
      default:
        return "Unbekannte Aufgabe";
    }
  };

  handleQuittung = async () => {
    try {
      await axios.put(`https://db.xocore.de/tr/spesen/del/${this.props.task_id}`);
      this.setState({ deleted: true }); // Set deleted to true on success
    } catch (error) {
      console.error("Fehler beim Löschen:", error);
    }
  };

  getLinkClassName = () => {
    switch (this.props.task_typ) {
      case 10:
        return "checkmate";
      case 20:
        return "order";
      case 30:
        return "hubspot";
      case 40:
        return "auftrag_d";
      default:
        return "tr/auftrag";
    }
  };

  render() {
    const { deleted } = this.state;
    const statusClassName = this.getStatusClassName();
    const linkadresse = this.getLinkClassName();

    return (
      <div className="list-group">
        <div className="list-group-item list-group-item-action">
          <div className="d-flex w-100 justify-content-between">
            <div className="col-md-2">
              <h5
                className={`mb-1 ${deleted ? "text-decoration-line-through" : ""}`}
              >
                {this.props.zusatzinfo} zum Auftrag {this.props.task_bezugsobjekt}
              </h5>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <div>{this.props.preis} EUR</div>
            <div>
              <Link to={`/${linkadresse}/${this.props.task_bezugsobjekt}`}>
                <button
                  onClick={this.props.showDetails}
                  type="button"
                  className="btn btn-secondary"
                >
                  Zum Auftrag
                </button>
              </Link>
              <button
                onClick={this.handleQuittung}
                type="button"
                className={`btn ${deleted ? "btn-success" : "btn-danger"}`}
              >
                {deleted ? "Gelöscht" : "Löschen"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Spesenelement;
