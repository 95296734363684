import React, { useState, useEffect } from 'react';
import { Table, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import './DataTable.css'; // Importiere die CSS-Datei
import { Link } from "react-router-dom";

const DataTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState(null);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // Funktion zum Sortieren der Daten
  const sortData = (key) => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });

    setFilteredData(sortedData);
    setSortConfig({ key, direction: 'ascending' });
  };

  // Funktion zum Umkehren der Sortierreihenfolge
  const handleSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      const direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
      setSortConfig({ ...sortConfig, direction });
      setFilteredData([...filteredData].reverse());
    } else {
      sortData(key);
    }
  };

  // Funktion zum Filtern der Daten
  const handleFilter = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredResults = data.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm)
      )
    );
    setFilteredData(filteredResults);
  };

  const handleAdd = () => {
    // Funktionalität für den Hinzufügen-Button
    alert("Hinzufügen Button geklickt");
  };

  return (
    <div className="table-container">
      <Form className="mb-3">
        <InputGroup>
          <Form.Control type="text" placeholder="Suchen..." onChange={handleFilter} />
        </InputGroup>
      </Form>
      <Table striped bordered hover responsive>
        <thead className="thead-dark">
          <tr>
            <th onClick={() => handleSort('trainer_name')}>Name</th>
            <th onClick={() => handleSort('trainer_vorname')}>Vorname</th>
            <th onClick={() => handleSort('trainer_tagesanzahl')}>Freie Tage</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index}>
              <td>{row.trainer_name}</td>
              <td>{row.trainer_vorname}</td>
              <td>{row.trainer_tagesanzahl}</td>
              <td>
              <Link to={`/trainer/profil/${row.trainer_id}`}>
                                <button className="btn btn-outline-danger">
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                </Link> 
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DataTable;
